import React, { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Page from "./img/page.svg"
import Button from "../Button/Button"
import "./style.scss"

export default function NotFound () {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if(pathname !== "/404") {
      navigate('/404', { replace: true })
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className="not-found">
      <div className="not-found-inner">
        <h1>Nikt tu jeszcze nie dotarł przed Tobą.</h1>
        <img src={Page} alt="" className="image"/>
        <div className="text">
          <h2>Lepiej zawrócić</h2>
          <p>Skorzystaj z nawigacji, by znaleźć drogę powrotną lub użyj poniższego portalu, by przenieść się na ekran główny.</p>
        </div>
        <div className="button-wrapper">
          <Button href="/">Portal powrotny</Button>
        </div>
      </div>
    </div>
  )
}
