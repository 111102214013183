import React from "react";
import { useUserState } from '../UserContext'

const RedirectComponent = () => {
    // document.location.href = process.env.REACT_APP_AZ_URL+"/aktywnosci/zone-plus"
    return (<div></div>)
}
 
export const ProtectedRoute = ({ children }) => {
    const userDetails = useUserState()

    return (
        !Boolean(userDetails.token?.uuid) ? (
            <RedirectComponent/>
        ) : (
            children
        )
    )
}

export const ProtectedComponent = ({ children }) => {
    const userDetails = useUserState()

    return (
        !Boolean(userDetails.token?.uuid) ? (
            null
        ) : (
            children
        )
    )
}