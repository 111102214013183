import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate
} from "react-router-dom";
import Modal from 'react-modal';
import { ProtectedRoute } from "./components/ProtectedComponent";
import { getUrlParam } from "./UrlParamsHelper";
import { loginByToken, useUserDispatch, useUserState } from "./UserContext";
import Exit from "./components/ErrorHandling/img/exit.svg"
import ErrorHandling from "./components/ErrorHandling"
import LogoutWarning from "./components/LogoutWarning";
import Maintenance from "./components/Maintenance";
import NotFound from "./components/NotFound";
import Switch from "./components/Switch"
import Welcome from "./pages/Welcome";
import Map from "./pages/Map";
import CaptureImage from "./pages/CaptureImage"
import Button from "./components/Button/Button";
import ARComponent from "./pages/ARComponent";
import { setCookie, getCookie } from "./Helpers";

function Transition() {
  const navigate = useNavigate()
  let location = useLocation();
  const dispatch = useUserDispatch()
  const { userDetails } = useUserState()
  const [loader, setLoader] = useState(true)
  const [backPopup, setBackPopup] = useState(false)
  const [debug, setDebug] = useState([])

  useEffect(() => {
    let token = null
    if (getUrlParam("token")) {
      token = getUrlParam("token")
    } else if (getCookie('ICToken')) {
      token = JSON.parse(decodeURIComponent(getCookie('ICToken'))).uuid
    }

    if(token && !Boolean(userDetails?.token?.uuid)) {
      loginByToken(dispatch, token)
    } else if(!userDetails.firstName) {
      document.location.href = process.env.REACT_APP_BRANDED_URL+"/strefa-punktow/skaner-zone"
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {setLoader(false)
    if(userDetails.firstName) {
      setLoader(false)
    }
  }, [userDetails])

  function createBinaryString (dec) {
    let arrBitwise = [0]

    for (let i=0; i<3; i++) {
      let mask = 1;
  
      const bit = dec & (mask << i);
  
      if(bit === 0) {
          arrBitwise[i] = 0;
      } else {
          arrBitwise[i] = 1;
      }
    }

    return arrBitwise
  }

  useEffect(() => {
    const co = createBinaryString(parseInt(getUrlParam("co")))
    
    if(co) {
      let cookieOptions = {}
      if(co[0] === 1) {
        cookieOptions.technical = true
      } else {
        cookieOptions.technical = false
      }

      if(co[1] === 1) {
        cookieOptions.performance = true
      }
      else {
        cookieOptions.performance = false
      }

      if(co[2] === 1) {
        cookieOptions.communication = true
      } else {
        cookieOptions.communication = false
      }

      localStorage.setItem("cookieOptions", JSON.stringify(cookieOptions))
    }
  }, [getUrlParam("co")])

  return (
      <>
        { loader ? <div className="loading">
          <h2>Trwa ładowanie...</h2>
        </div> : <div className="app">
          <LogoutWarning/>
          <div className="page-holder">
            <button className={ location.pathname === "/skaner" || location.pathname === "/mapa" || location.pathname === "/skaner/" || location.pathname === "/mapa/" || location.pathname.indexOf('/ar') === 0 ? "back-button" : "back-button az" } onClick={ () => { location.pathname === "/skaner" || location.pathname === "/mapa" || location.pathname === "/skaner/" || location.pathname === "/mapa/" || location.pathname.indexOf('/ar') === 0 ? navigate("/") : setBackPopup(true) } }></button>
            <Switch/>
            <div>
              <Routes location={location}>
                <Route path={`${process.env.PUBLIC_URL}/`} exact element={<ProtectedRoute><Welcome/></ProtectedRoute>}/>
                <Route path={`${process.env.PUBLIC_URL}/skaner`} exact element={<ProtectedRoute><CaptureImage setDebug={setDebug}/></ProtectedRoute>}/>
                <Route path={`${process.env.PUBLIC_URL}/skaner/kupon`} exact element={<ProtectedRoute><CaptureImage setDebug={setDebug}/></ProtectedRoute>}/>
                <Route path={`${process.env.PUBLIC_URL}/mapa`} exact element={<ProtectedRoute><Map/></ProtectedRoute>}/>
                <Route path={`${process.env.PUBLIC_URL}/ar`}>
                  <Route index element={<ProtectedRoute><ARComponent/></ProtectedRoute>} />
                  <Route path=":type" element={<ProtectedRoute><ARComponent/></ProtectedRoute>} />
                  <Route path=":type/:location" element={<ProtectedRoute><ARComponent/></ProtectedRoute>} />
                </Route>
                <Route path={`${process.env.PUBLIC_URL}/503`} exact element={<Maintenance/>}/>
                <Route path={`${process.env.PUBLIC_URL}/404`} exact element={<NotFound/>}/>
                <Route path={`${process.env.PUBLIC_URL}/*`} exact element={<NotFound/>}/>
              </Routes>
            </div>
            <ErrorHandling/>
          </div>
          <Modal isOpen={backPopup} className={"error-modal"} overlayClassName={"error-overlay"}>
            <>
              <div className="button-wrapper">
                <button className='close-button'>
                  <img src={Exit} alt="" onClick={ () => { setBackPopup(false) } } />
                </button>
              </div>
              <div className="content">
                <h2>Czy na pewno chcesz opuścić Zone+</h2>
                <div className='error-description'>i wrócić do Inspiration Club?</div>
                <div className="buttons">
                  <Button action={ () => { setBackPopup(false) } } btnClass="white">Nie</Button>
                  <Button action={ () => { document.location.href = process.env.REACT_APP_BRANDED_URL + "/strefa-punktow/skaner-zone?ageGate=false&token=" + JSON.parse(sessionStorage.getItem('token')).uuid } } btnClass="white">Tak</Button>
                </div>
              </div>
            </>
          </Modal>
          { debug?.length > 0 && getUrlParam("debug") === "true" && <div className='coords-stats'>
            <div className='close-debug' onClick={() => { setDebug([]) }}></div>
            <p><strong>Detected:</strong></p>
            <ul>
            { debug.map((item, i) => {
              return (
                <li key={i} className={ item.DetectedText === "glo" || item.DetectedText === "neo" || item.DetectedText === "gl" ? "valid" : "" }>
                  <p className="flex"><span>{item.DetectedText}:</span> <span>{item.Confidence.toFixed(2)}</span></p>
                </li>
              )
            }) }
            </ul>
          </div> }
        </div> }
      </>
  );
}

export default function MainView() {
  return (
    <Router>
      <Transition />
    </Router>
  );
}
