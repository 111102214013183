import { useRef, useCallback, useState, useEffect } from 'react'
import './style.scss';
import Webcam from "react-webcam";
import { RekognitionClient, DetectTextCommand } from "@aws-sdk/client-rekognition"
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import Scan from "../Welcome/img/scan.svg"
import { ReactComponent as Target } from "./img/target.svg"
import { ReactComponent as TargetChecked } from "./img/target-checked.svg"
import { useGeolocated } from "react-geolocated";
import { places } from "../../data/places"
import Arrow from "../Welcome/img/arrow.svg"
import { useNavigate, useLocation } from 'react-router-dom'
import { getAccountDetails, useUserDispatch, useUserState } from '../../UserContext';
import { showErrorPopup, translateError } from '../../components/ErrorHandling';
import { sendEvent } from '../../Helpers';

let inTarget = false
let targetName = ""

const Toast = ({desc}) => {
  let cl = 'toast'
  if (desc.desc) cl += ' show'
  if (desc.error) cl += ' error'
  return (
    <div className={ cl }>
      <p dangerouslySetInnerHTML={{ __html: desc?.desc }}></p>
    </div>
  )
}

const Tutorial = ({onClose, payout}) => {
  const [step, setStep] = useState(0)
  const [animate, setAnimate] = useState("")
  
  const steps = [
      {
          title: "Tu są punkty!",
          code: "<strong>" + payout + "</strong> pkt",
          description: "Udało Ci się ukończyć pierwszy krok, a&nbsp;punkty wpadły na Twoje konto."
      },
      {
          title: "Na mieście czekają kolejne nagrody",
          description: "Od teraz raz w tygodniu masz szansę na<br/>nowe kupony, skanując paczkę neo™ lub<br/>logo glo™ w jednym z punktów biorących<br/>udział w akcji na mapie Polski<br/><span>Pamiętaj, żeby na paczce<br/>widoczne było logo neo™</span>"
      }
  ]

  useEffect(() => {
      setTimeout(() => { setAnimate("animate") }, 800)
  }, [])

  return (
      <div className={ 'bottom-modal tutorial ' + animate }>
          <div className='content'>
              <h3 dangerouslySetInnerHTML={{ __html: steps[step].title }}></h3>
              { steps[step].code ? <div className='code' dangerouslySetInnerHTML={{ __html: steps[step].code }}></div> : null }
              <p dangerouslySetInnerHTML={{ __html: steps[step].description }}></p>
              <div className="paginate">
                  <span>{step+1}</span>
                  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 29L29.2843 0.715726" stroke="white"></path></svg>
                  <span>{2}</span>
              </div>
          </div>
          <div className="button-wrap">
              <div className="button-component">
                <button className='next' onClick={ () => {
                    if(step < 1) {
                        setStep(step+1)
                    } else {
                        setAnimate("")
                        setTimeout(() => { onClose() }, 500)
                    }
                } }>Dalej</button>
              </div>
          </div>
      </div>
  )
}

const Intro = ({onClose, code}) => {
  const navigate = useNavigate()
  const [step, setStep] = useState(0)
  const [animate, setAnimate] = useState("")

  const steps = [
      {
          title: "Kupon!",
          code: "KOD: "+code ,
          description: <>Kupon trafił właśnie na Twoje konto. <br/>Przejdź do <a href={"" + process.env.REACT_APP_BRANDED_URL + "/strefa-kuponow/odebrane-kupony?ageGate=false&token=" + JSON.parse(sessionStorage.getItem('token')).uuid } target="_self" onClick={(e) => {
            e.preventDefault()
            navigate("/skaner", { replace: true })
            document.location.href = process.env.REACT_APP_BRANDED_URL + "/strefa-kuponow/odebrane-kupony?ageGate=false&token=" + JSON.parse(sessionStorage.getItem('token')).uuid
          }}>Moich Nagród</a>.</>
      },
      {
          title: "Wróć po więcej!",
          description: <>Kolejny kupon na mieście pojawi się w następnym tygodniu. <br/><br/>Nie pozwól, aby Ci uciekł.</>
      }
  ]

  useEffect(() => {
      setTimeout(() => { setAnimate("animate") }, 800)
  }, [])

  return (
      <div className={ 'bottom-modal intro ' + animate }>
          <div className='content'>
              <h3 dangerouslySetInnerHTML={{ __html: steps[step].title }}></h3>
              { steps[step].code ? <div className='code' dangerouslySetInnerHTML={{ __html: steps[step].code }}></div> : null }
              <p>{steps[step].description}</p>
              <div className="paginate">
                  <span>{step+1}</span>
                  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 29L29.2843 0.715726" stroke="white"></path></svg>
                  <span>{2}</span>
              </div>
          </div>
          <div className="button-wrap">
              <div className="button-component">
                <button className='next' onClick={ () => {
                  if(step < 1) {
                    setStep(step+1)
                  } else {
                      setAnimate("")
                      setTimeout(() => { onClose() }, 500)
                  }
                } }>Dalej</button>
              </div>
          </div>
      </div>
  )
}

const CameraPrompt = () => {
  const [animate, setAnimate] = useState("")
  
  const steps = [
      {
          title: "Ciemno tu!",
          description: "Zezwól na używanie aparatu, aby skorzystać ze skanera Zone+."
      }
  ]

  useEffect(() => {
      setTimeout(() => { setAnimate("animate") }, 800)
  }, [])

  return (
      <div className={ 'bottom-modal full ' + animate }>
          <div className='content'>
              <h3 dangerouslySetInnerHTML={{ __html: steps[0].title }}></h3>
              <p dangerouslySetInnerHTML={{ __html: steps[0].description }}></p>
          </div>
      </div>
  )
}

function App({setDebug}) {
  const dispatch = useUserDispatch()
  const { accountDetails, coupon } = useUserState()
  const { pathname } = useLocation()
  const firstRun = accountDetails?.zonePlus?.tutorial.available
  const webcamRef = useRef(null);
  const [image, setImage] = useState("")
  // const [data, setData] = useState([])
  const [coordStats, showCoordStats] = useState(false)
  const [toastDesc, setToastDesc] = useState("")
  const distance = 0.0012
  const [payout, setPayout] = useState(0)
  const [code, setCode] = useState("")
  const navigate = useNavigate();
  const { coords } =
    useGeolocated({
        positionOptions: {
            enableHighAccuracy: true,
        },
        watchPosition: true,
        userDecisionTimeout: 5000,
    });

  const [cameraPermission, setCameraPermission] = useState("")
  // useEffect(() => {
  //   navigator.permissions.query({ name: 'camera' }).then((permissionStatus) => {
  //       console.log(`camera permission state is ${permissionStatus.state}`);
  //       setCameraPermission(permissionStatus.state)
  //       permissionStatus.onchange = () => {
  //         console.log(`camera permission state has changed to ${permissionStatus.state}`);
  //         setCameraPermission(permissionStatus.state)
  //       };
  //     });
  //   //eslint-disable-next-line
  // }, [])

  useEffect(() => {
    getAccountDetails(dispatch)
    sendEvent("ZONE_SCAN_INIT")
    //dispatch({type:"COUPON",payload:'ESW12345'})
    //eslint-disable-next-line
  }, [])

  const getSlug = (name) => {
    let value = name
    if(value) {
      value = value.toLowerCase()
      var from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆĞÍÌÎÏİŇÑÓÖÒÔÕØŘŔŠŞŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇğíìîïıňñóöòôõøðřŕšşťúůüùûýÿžþÞĐđßÆa·/_,:;ęĘóÓąĄśŚłŁżŻźŹćĆńŃ"
      var to   = "AAAAAACCCDEEEEEEEEGIIIIINNOOOOOORRSSTUUUUUYYZaaaaaacccdeeeeeeeegiiiiinnooooooorrsstuuuuuyyzbBDdBAa______eEoOaAsSlLzZzZcCnN"

      for (var i = 0, l = from.length; i < l; i++) {
        value = value.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
      }

      value = value.replace(/[^a-z0-9 -]/g, '')
              .replace(/\s+/g, '_')
              .replace(/-+/g, '_')
    }
    return value
  }

  useEffect(() => {
    if(coords) {
      let targetSet = false
      const lat = coords.latitude.toFixed(3)
      const lng = coords.longitude.toFixed(3)
      for (let i = 0; i < places.length; i++) {
        const element = places[i];
        const placeLat = element.lat.toFixed(3)
        const placeLng = element.lng.toFixed(3)
        if(parseFloat(lat) < parseFloat(placeLat)+distance && parseFloat(lat) > parseFloat(placeLat)-distance && parseFloat(lng) < parseFloat(placeLng)+distance && parseFloat(lng) > parseFloat(placeLng)-distance) {
          targetSet = true
          targetName = getSlug(element.name + " " + element.address)
        }
      }
      if(targetSet === true) {
        inTarget = true
      } else {
        inTarget = false
        targetName = ""
      }
    }
  }, [coords])

  const redeemTutorialBonus = () => {
    const data = {
      activation: {
        ident: "ar_tutorial",
        payload: null
      }
    }
    
    fetch(process.env.REACT_APP_API_URL + "/alterzone/activation/solution/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem('token')).uuid
      },
      body: JSON.stringify(data)
    })
      .then((response) => {
        return response.json()
      })
      .then(
        (result) => {
          if (result.status?.success) {
            setPayout(result.data.payout)
            // setCorrectAnswersMap(result.data.answers)
          } else {
            showErrorPopup(translateError(result.data.error), result.meta.ts)
          }
        },
        (error) => {
          showErrorPopup(translateError("generic"))
          console.log(error)
        }
      )
  }

  const redeemAward = () => {
    const data = {
      catalogue: {
        ident: process.env.REACT_APP_AWARD_IDENT
      },
      payload: {
        type: "test"
      }
    }
    
    fetch(process.env.REACT_APP_API_URL + "/alterzone/prize/plus/redeem", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem('token')).uuid
      },
      body: JSON.stringify(data)
    })
      .then((response) => {
        return response.json()
      })
      .then(
        (result) => {
          if (result.status?.success) {
            setCode(result.data?.award?.code)
            // setCorrectAnswersMap(result.data.answers)
          } else {
            showErrorPopup(translateError(result.data.error), result.meta.ts)
          }
        },
        (error) => {
          showErrorPopup(translateError("generic"))
          console.log(error)
        }
      )
  }

  useEffect(() => {
    if(pathname === "/skaner/kupon") {
      /*if(accountDetails?.zonePlus?.showTutorial) {
        redeemTutorialBonus()
      } else {
        redeemAward()
      }*/
    }
    //eslint-disable-next-line
  }, [])

  const sendImgToApi = async (image, ua, lat, long, inTarget, status) => {
    const mimeString = image.split(':')[1].split(';')[0]
    const blob = new Blob([ua], {type: mimeString})
    
    let payloadForm = new FormData()

    const input = {
      "scan": {
        "status": status,
        "latitude": lat || "",
        "longitude": long || "",
        "inTarget": inTarget !== "" ? inTarget : "wrong_location",
        "tutorial": accountDetails?.zonePlus?.tutorial?.available
      }
    }

    payloadForm.append('files[]', blob)
    payloadForm.append('_input', JSON.stringify(input))
    
    const response = await fetch(process.env.REACT_APP_API_URL + "/ar/scan", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem('token')).uuid
      },
      body: payloadForm,
    })

    const res = await response.json()

    if (res.status.success && status != "fail") {
      if (res.data.awarded.tutorial) {
        navigate(`/ar/${status}/success`, { replace: true })
      } else if (res.data.awarded.coupon) {
        dispatch({type:"COUPON",payload: res.data.awarded.coupon.code})
        navigate(`/ar/${status}/success`, { replace: true })
      } else if ( !inTarget ) {
        navigate(`/ar/${status}/wrong_location`, { replace: true })
      } else if (res.data.awarded.coupon === null) {
        navigate(`/ar/${status}/unavailable`, { replace: true })
      }
    }
  }

  const sendImage = (image, lat, long) => {
    sendEvent("ZONE_SCAN_START")
    setToastDesc({desc: "Trwa skanowanie...", error: false})    
    const client = new RekognitionClient({
      region: "eu-central-1",
      credentials: fromCognitoIdentityPool({
        client: new CognitoIdentityClient({ region: "eu-central-1" }),
        identityPoolId: 'eu-central-1:23db6dd2-96c6-4725-af6d-ef9ada1300f5',
      })
    })

    let img = atob(image.split("data:image/jpeg;base64,")[1])

    const length = img.length;
    const imageBytes = new ArrayBuffer(length);
    const ua = new Uint8Array(imageBytes);
    for (var i = 0; i < length; i++) {
      ua[i] = img.charCodeAt(i);
    }

    const params = {
      Image: {
        Bytes: ua
      },
      MaxLabels: 10,
      MinConfidence: 20,
      ProjectVersionArn: "arn:aws:rekognition:eu-central-1:773704893069:project/GLO/version/GLO.2022-06-15T13.19.02/1655291942240"
    };
    
    const command = new DetectTextCommand(params);

    client.send(command).then(
      (data) => {
        // console.log(data)
        // setData(data.TextDetections)
        setDebug(data.TextDetections)
        setToastDesc("")
        let glo = false
        let neo = false
        for (let i = 0; i < data.TextDetections.length; i++) {
          const element = data.TextDetections[i];
          if(element.DetectedText === "neo") {
            neo = true
            break
          } else if(element.DetectedText === "glo" || element.DetectedText === "gl" || element.DetectedText.toLowerCase().indexOf("podgrzewa prawdziwy") !== -1) {
            glo = true
            break
          }
        }

        // if(!accountDetails?.zonePlus.showTutorial && !inTarget) {
        //   setTimeout(() => { setToastDesc("<strong>Szukaj kuponów na mieście!</strong><br/>Odwiedź wybrany punkt w&nbsp;Warszawie i&nbsp;raz w tygodniu odbieraj nowe nagrody.") }, 400)
        //   setTimeout(() => { setToastDesc("") }, 4400)
        // } else if(!accountDetails?.zonePlus?.catalogue[0]?.available) {
        //   setTimeout(() => { setToastDesc("<strong>Masz już kupon z tego tygodnia!</strong><br/>Zeskanuj paczkę neo™ lub logo glo™ w&nbsp;kolejnym tygodniu.") }, 400)
        //   setTimeout(() => { setToastDesc("") }, 3400)
        // } else 
        if(!glo && !neo) {
          setTimeout(() => { setToastDesc({desc: "<strong>Wystąpił błąd</strong><br/>Spróbuj zeskanować raz jeszcze.<br/><strong>Pamiętaj, żeby na paczce<br/>widoczne było logo neo™</strong>", error: true}) }, 400)
          setTimeout(() => { setToastDesc("") }, 3400)
          sendImgToApi(image, ua, lat, long, targetName, neo ? "neo" : glo ? "glo" : "fail")
        } else {
          const typeParam = glo ? "glo" : "neo"
          const locationParam = inTarget ? "success" : "fail"
          
          if(glo) {
            sendEvent("ZONE_SCAN_SUCCESS_GLO")
          } else {
            sendEvent("ZONE_SCAN_SUCCESS_NEO")
          }

          sendImgToApi(image, ua, lat, long, targetName, neo ? "neo" : glo ? "glo" : "fail")
          
        }
        
        if(targetName !== "") sendEvent("ZONE_LOCATION_"+targetName)
        setImage("")
      },
      (error) => {
        console.log(error)
        setToastDesc("")
        setTimeout(() => { setToastDesc({desc: "<strong>Wystąpił błąd</strong><br/>Spróbuj zeskanować raz jeszcze.<br/><strong>Pamiętaj, żeby na paczce<br/>widoczne było logo neo™</strong>", error: true}) }, 400)
        setTimeout(() => { setToastDesc("") }, 3400)
        setImage("")
      }
    );
  }

  const capture = useCallback(
    (lat, long) => {
      const imageSrc = webcamRef.current.getScreenshot();
      setImage(imageSrc)
      console.log(lat, long)
      sendImage(imageSrc, lat, long)
    },
    //eslint-disable-next-line
    [webcamRef]
  );

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "environment"
  };

  return (
    <div className="capture-image">
      { !firstRun ? <div className={ inTarget ? 'location in-target' : 'location' }>
        <Target className="target"/>
        <TargetChecked className="target-checked"/>
      </div> : null }
      { coordStats && <div className='coords-stats'>
        <p><strong>lat: </strong>{ parseFloat(coords?.latitude) }</p>
        <p><strong>lng: </strong>{ parseFloat(coords?.longitude) }</p>
        { places.map((item, i) => {
          let valid = false

          if(parseFloat(coords?.latitude) < parseFloat(item.lat)+distance && parseFloat(coords?.latitude) > parseFloat(item.lat)-distance && parseFloat(coords?.longitude) < parseFloat(item.lng)+distance && parseFloat(coords?.longitude) > parseFloat(item.lng)-distance) {
            valid = true
          }

          return (
            <div key={i+coords?.latitude} className={valid ? "valid" : ""}>
              <p><strong>{item.name}:</strong></p>
              <p>{"placeLat: " + parseFloat(item.lat).toFixed(7)}</p>
              <p>{"placeLat+: " + (parseFloat(item.lat)+distance).toFixed(7)}</p>
              <p>{"placeLat-: " + (parseFloat(item.lat)-distance).toFixed(7)}</p>
              <p>{"placeLng: " + parseFloat(item.lng).toFixed(7)}</p>
              <p>{"placeLng+: " + (parseFloat(item.lng)+distance).toFixed(7)}</p>
              <p>{"placeLng-: " + (parseFloat(item.lng)-distance).toFixed(7)}</p>
            </div>
          )
        }) }
      </div> }
      <Webcam key={cameraPermission} className='camera' audio={false}
        height={720}
        screenshotFormat="image/jpeg"
        ref={webcamRef}
        videoConstraints={videoConstraints}/>
      <div className='scan'>
        <div className='left'></div>
        <div className='right'></div>
      </div>
      <button className="button-scan" disabled={toastDesc ? true : false} onClick={() => { capture(coords?.latitude, coords?.longitude) }}><img src={Scan} alt="Capture"/></button>
      {image && <div className='capture-holder'>
        <div className='close' onClick={() => { setImage("") }}></div>
        <img className='captured' src={image} alt=""/>
        {/* <ul>
          {data?.map((item, i) => {
            return (
              <li key={i} className="bounding" style={{width: (item.Geometry.BoundingBox.Width*100)+"%", height: (item.Geometry.BoundingBox.Height*100)+"%", left: (item.Geometry.BoundingBox.Left*100)+"%", top: (item.Geometry.BoundingBox.Top*100)+"%"}}><span>{item.Confidence.toFixed(2)}</span></li>
            )
          })}
        </ul> */}
        <div className="diode">
          <div className="laser"></div>
        </div>
      </div> }
      <Toast desc={ toastDesc }/>
      {/* { data && <div><code>{JSON.stringify(data)}</code></div> } */}
      {/* { cameraPermission === "granted" ? <> */}
        { coupon && pathname === '/skaner/kupon' ? <Intro code={coupon} onClose={() => { getAccountDetails(dispatch); navigate("/skaner", { replace: true }); setCode("") }}/> : null }
        { !coupon && pathname === '/skaner/kupon' ? <Tutorial payout={30} onClose={() => { getAccountDetails(dispatch); navigate("/skaner", { replace: true }); setPayout(0) }}/> : null }
      {/* </> : <>
        <CameraPrompt/>
      </> } */}
    </div>
  );
}

export default App;
