import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
// import * as ThreeMeshUI from "three-mesh-ui";
//import { ARButton } from './ARButton';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';
// import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';
import {DeviceOrientationControls} from './DeviceOrientationControls';
import { useNavigate, useParams } from 'react-router-dom';
import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment.js';
import Webcam from "react-webcam";
import Arrow from "../Welcome/img/arrow.svg"
import { useUserState } from '../../UserContext';
import _ from 'lodash'

const Intro = ({onClose, playAnimate, step}) => {
  //const [step, setStep] = useState(0)
  const [animate, setAnimate] = useState("")

  const steps = [
    {
        title: "Brawo!",
        description: "<strong>Skanowanie zakończone!</strong><br/><br/>Wkrocz w nową rzeczywistości i sprawdź, jaką nagrodę udało Ci się znaleźć."
    },
    {
      title: "Skanowanie zakończone!",
      description: "<strong>Masz już kupon z tego tygodnia!</strong><br/><br/>Zeskanuj paczkę neo™ lub logo glo™<br/>w kolejnym tygodniu.<br/><span>Pamiętaj, żeby na paczce<br/>widoczne było logo neo™</span>"
    },
    {
      title: "Skanowanie zakończone!",
      description: "<strong>Kupony są na mieście!</strong><br/><br/>Odwiedź wybrany punkt biorący<br/>udział w akcji na mapie Polski.<br/>Kupony pojawiają się tam raz na tydzień."
    }
  ]

  useEffect(() => {
    playAnimate ?
      setTimeout(() => { setAnimate("animate") }, 800) :
      setAnimate("")
  }, [playAnimate])

  return (
      <div className={ 'bottom-modal intro ' + animate }>
          <div className='content'>
              <h3 dangerouslySetInnerHTML={{ __html: steps[step].title }}></h3>
              { steps[step].code ? <div className='code' dangerouslySetInnerHTML={{ __html: steps[step].code }}></div> : null }
              <p dangerouslySetInnerHTML={{ __html: steps[step].description }}></p>
          </div>
          <div className="button-wrap">
            <div className="button-component">
              <button className='next' onClick={ () => {
                  setAnimate("")
                  onClose()
                  
              } }>Dalej</button>
            </div>
          </div>
      </div>
  )
}

function ARComponent() {
  const { type, location } = useParams()
  const { accountDetails, coupon } = useUserState()
  const refContainer = useRef();
  const refButton = useRef();
  const [renderer, setRenderer] = useState();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false)
  const [clock, setClock] = useState()
  const [scene, setScene] = useState()
  const [camera, setCamera] = useState()
  //const [controls, setControls] = useState()
  const [mixer, setMixer] = useState()
  const [mixer2, setMixer2] = useState()
  const [mixer3, setMixer3] = useState()
  const [mixer4, setMixer4] = useState()
  const [mixer5, setMixer5] = useState()
  const [mixer6, setMixer6] = useState()
  const [showIntro, setShowIntro] = useState(true)
  const [route, setRoute] = useState(0)
  const [frustum, setFrustum] = useState(0)
  const [mesh, setMesh] = useState(0)
  let currentSession = null;
  let controls;

  //console.log(_.filter(accountDetails?.zonePlus?.catalogue, { ident: process.env.REACT_APP_AWARD_IDENT })[0]?.available)

  useEffect(() => {
    const available = _.filter(accountDetails?.zonePlus?.catalogue, { ident: process.env.REACT_APP_AWARD_IDENT })[0]?.available
    if(location) {
      if ( location === 'success') {
        setRoute(0)
      } else if ( location === 'wrong_location') {
        setRoute(2)
      } else if ( location === 'unavailable') {
        setRoute(1)
      }
    } 
    
    init()

  }, []);

  const init = () => {
    const { current: container } = refContainer;
    const { current: buttonContainer } = refButton;
    if (container && !renderer) {
      //const container = document.createElement('div');
      //document.body.appendChild(container);
      let model, model2, model3, model4, model5, model6, mixer, mixer2, mixer3, mixer4, mixer5, mixer6;
      let clock = new THREE.Clock();
      setClock(clock)

      const scene = new THREE.Scene();
      setScene(scene)
    
      const camera = new THREE.PerspectiveCamera(70, window.innerWidth / window.innerHeight, 0.01, 100000);
      setCamera(camera)

      const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth/2, window.innerHeight/2);
      renderer.xr.enabled = true; // we have to enable the renderer for webxr
      //renderer.outputEncoding = THREE.sRGBEncoding;
      container.appendChild(renderer.domElement);
      setRenderer(renderer);

      const pmremGenerator = new THREE.PMREMGenerator( renderer );
      //scene.background = new THREE.Color( 0xf0f0f0 );
      scene.environment = pmremGenerator.fromScene( new RoomEnvironment(), 0.01 ).texture;

      //const controls = new OrbitControls( camera, renderer.domElement );
      //controls.enableZoom = false;
      //controls.enablePan = false;
      camera.position.set( 0,0,0.1 );
      //const controls = new DeviceOrientationControls( camera );
      //setControls(controls)
      //controls.update();

      //var axesHelper = new THREE.AxesHelper( 5 );
      //scene.add( axesHelper );

      const light = new THREE.HemisphereLight(0xffffff, 0xbbbbff, 0.1);
      light.position.set(0.5, 3, 0.25);
      scene.add(light);

      let modelUrl = ''
      // Add a GLTF model to the scene
      if (accountDetails?.zonePlus?.tutorial?.available) {
        modelUrl = '/assets/BAT_AR_13_kupon_hover.gltf';
      } else {
        modelUrl = '/assets/BAT_AR_floor_KUPON_hover_new.gltf';
      }
      const modelUrl2 = '/assets/nowe/BAT_AR_20_obiekty_ticket_120klatek.gltf';

      const modelUrl3 = '/assets/nowe/BAT_AR_21_obiekty_main_249klatek.gltf';

      const modelUrl4 = '/assets/nowe/BAT_AR_20_linie_doublesided_120klatek.gltf';

      const modelUrl5 = '/assets/nowe/BAT_AR_20_partikle_doublesided_120klatek.gltf';

      const modelUrl6 = '/assets/BAT_AR_19_falka_SMOOTHED.gltf';
      
      const loader = new GLTFLoader();
      loader.load(
        modelUrl,
        function (gltf) {
          model = gltf.scenes[0];
          model.rotation.y = 3;
          model.position.z = -2;
          //model.position.y = 0.3;
          //console.log(model)
          model.scale.set(0.2, 0.2, 0.2)
          if ((accountDetails?.zonePlus?.prize?.available || accountDetails?.zonePlus?.tutorial?.available) && location === 'success') {
            scene.add(model);
          }
          console.log("Model added to scene");
          mixer = new THREE.AnimationMixer( gltf.scene );
          setMixer(mixer)
        
          gltf.animations.forEach( ( clip ) => {
            
              mixer.clipAction( clip ).play();
            
          } );
          //setTimeout(()=>{render()},500)
          //render()
            //animate()
          
        },
        function (xhr) {
          // console.log((xhr.loaded / xhr.total * 100) + '% loaded' );
        },
        function (error) {
          console.error(error);
        }
      );

      loader.load(
        modelUrl2,
        function (gltf) {
          model2 = gltf.scenes[0];
          model2.position.z = -2;
          model2.position.y = -12;
          model2.position.x = -0.5;
          model2.scale.set(0.1, 0.1, 0.1)
          model2.rotation.y = -0.2;
          scene.add(model2);
          //console.log(model2)
          console.log("Model 2 added to scene");
          mixer2 = new THREE.AnimationMixer( gltf.scene );
          
          setMixer2(mixer2)

          gltf.animations.forEach( ( clip ) => {
            
              mixer2.clipAction( clip ).play();
            
          } );
        },
        function (xhr) {
          // console.log((xhr.loaded / xhr.total * 100) + '% loaded' );
        },
        function (error) {
          console.error(error);
        }
      );

      loader.load(
        modelUrl3,
        function (gltf) {
          model3 = gltf.scenes[0];
          model3.position.z = -3;
          model3.position.y = -4;
          model3.position.x = 0;
          model3.scale.set(0.04, 0.04, 0.04)
          model3.rotation.y = -0.2;
          scene.add(model3);
          //console.log(model2)
          console.log("Model 3 added to scene");
          mixer3 = new THREE.AnimationMixer( gltf.scene );
          
          setMixer3(mixer3)

          gltf.animations.forEach( ( clip ) => {
            
              mixer3.clipAction( clip ).play();
            
          } );
        },
        function (xhr) {
          // console.log((xhr.loaded / xhr.total * 100) + '% loaded' );
        },
        function (error) {
          console.error(error);
        }
      );

      loader.load(
        modelUrl4,
        function (gltf) {
          model4 = gltf.scenes[0];
          model4.position.z = -5;
          model4.position.y = -9.4;
          model4.position.x = -0.1;
          model4.scale.set(0.08, 0.08, 0.08)
          model4.rotation.y = -0.1;
          scene.add(model4);
          //console.log(model4)
          console.log("Model 4 added to scene");
          mixer4 = new THREE.AnimationMixer( gltf.scene );
          
          setMixer4(mixer4)

          gltf.animations.forEach( ( clip ) => {
            
              mixer4.clipAction( clip ).play();
            
          } );
        },
        function (xhr) {
          // console.log((xhr.loaded / xhr.total * 100) + '% loaded' );
        },
        function (error) {
          console.error(error);
        }
      );

      loader.load(
        modelUrl5,
        function (gltf) {
          model5 = gltf.scenes[0];
          model5.position.z = -9;
          model5.position.y = -9;
          //model5.position.x = -0.5;
          model5.scale.set(0.08, 0.08, 0.08)
          //model5.rotation.y = -0.2;
          scene.add(model5);
          //console.log(model5)
          console.log("Model 5 added to scene");
          mixer5 = new THREE.AnimationMixer( gltf.scene );
          
          setMixer5(mixer5)

          gltf.animations.forEach( ( clip ) => {
            
              mixer5.clipAction( clip ).play();
            
          } );
        },
        function (xhr) {
          // console.log((xhr.loaded / xhr.total * 100) + '% loaded' );
        },
        function (error) {
          console.error(error);
        }
      );

      loader.load(
        modelUrl6,
        function (gltf) {
          model6 = gltf.scenes[0];
          model6.position.z = -2;
          model6.position.y = -6.3;
          //model6.position.x = -0.5;
          model6.scale.set(0.04, 0.04, 0.04)
          model6.rotation.y = -0.2;
          scene.add(model6);
          //console.log(model6)
          console.log("Model 6 added to scene");
          mixer6 = new THREE.AnimationMixer( gltf.scene );
          
          setMixer6(mixer6)

          gltf.animations.forEach( ( clip ) => {
            
              mixer6.clipAction( clip ).play();
            
          } );
        },
        function (xhr) {
          // console.log((xhr.loaded / xhr.total * 100) + '% loaded' );
        },
        function (error) {
          console.error(error);
        }
      );

      let mes;
      
      const geometry = new THREE.IcosahedronGeometry(0.1, 1);
      const material = new THREE.MeshPhongMaterial({
        color      :  new THREE.Color("rgb(226,35,213)"),
        shininess  :  6,
        flatShading:  true,
        transparent: 1,
        opacity    : 0.8
      });
      
      mes = new THREE.Mesh(geometry, material);
      mes.position.set(1, 0, -0.5);

      setMesh(mes)
      scene.add(mes);

      /*const textContainer = new ThreeMeshUI.Block({
        width: 1,
        height: 0.5,
        padding: 0.1,
        fontFamily: '/assets/Roboto-msdf.json',
        fontTexture: '/assets/Roboto-msdf.png',
       });
       textContainer.position.x = 2;
       textContainer.rotation.y = 5;
       //
       
       const text = new ThreeMeshUI.Text({
        content: "Odbierz kupon"
       });
       
       textContainer.add( text );
       
       // scene is a THREE.Scene (see three.js)
       scene.add( textContainer );
       
       // This is typically done in the render loop :
       ThreeMeshUI.update();*/

      //buttonContainer.appendChild(ARButton.createButton(renderer, {}, ()=>navigate('/skaner/kupon') ));

      window.addEventListener('resize', onWindowResize, false);

      function onWindowResize() {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
    
        renderer.setSize(window.innerWidth, window.innerHeight);
      }

      /*const frustum = new THREE.Frustum()
      setFrustum(frustum)
      const matrix = new THREE.Matrix4().multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse)
      frustum.setFromProjectionMatrix(matrix)


      console.log(frustum)*/
      return () => {
        //cancelAnimationFrame(req);
        renderer.dispose();
      };
    }
  }

  const animate = () => {
    renderer.setAnimationLoop(render);
  }

  const render = () => {
    if (controls) {
      controls.update();
    }
    //ThreeMeshUI.update();
    let delta = clock.getDelta();

    if ( mixer ) mixer.update( delta );
    if ( mixer2 ) mixer2.update( delta );
    if ( mixer3 ) mixer3.update( delta );
    if ( mixer4 ) mixer4.update( delta );
    if ( mixer5 ) mixer5.update( delta );
    if ( mixer6 ) mixer6.update( delta );

    /*const matrix = new THREE.Matrix4().multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse)
    frustum.setFromProjectionMatrix(matrix)
    if (frustum && mesh && frustum.containsPoint(mesh.position)) {
      console.log('In view')
    }*/

    renderer.render(scene, camera);
  }

  const checkAR = () => {
    const isS22S23 = navigator.userAgent.indexOf("SM-S9") !== -1 ? true : false
    console.log("is galaxy s22 or s23: ", isS22S23)
    
    // if ( 'xr' in navigator && !isS22S23 ) {

		// 	navigator.xr.isSessionSupported( 'immersive-ar' ).then( function ( supported ) {

		// 		supported ? startAR() : start3D();

		// 	} ).catch( start3D );


		// } else {
    //   start3D()

		// }
    start3D()
  }

  const startAR = () => {
    if ( currentSession === null ) {
      let sessionInit = {};

      const overlay = document.createElement( 'div' );
      overlay.style.display = 'none';
      document.body.appendChild( overlay );

      const svg = document.createElementNS( 'http://www.w3.org/2000/svg', 'svg' );
      svg.setAttribute( 'width', 38 );
      svg.setAttribute( 'height', 38 );
      svg.style.position = 'absolute';
      svg.style.right = '20px';
      svg.style.top = '20px';
      svg.style.zIndex = '99999';
      svg.addEventListener( 'click', () => {

        currentSession.end();
        navigate('/skaner', { replace: true })

      } );
      overlay.appendChild( svg );

      const path = document.createElementNS( 'http://www.w3.org/2000/svg', 'path' );
      path.setAttribute( 'd', 'M 12,12 L 28,28 M 28,12 12,28' );
      path.setAttribute( 'stroke', '#fff' );
      path.setAttribute( 'stroke-width', 2 );
      svg.appendChild( path );

      if ( sessionInit.optionalFeatures === undefined ) {

        sessionInit.optionalFeatures = [];

      }

      const cButton = document.createElement( 'div' );
      cButton.style.position = 'absolute';
      cButton.style.bottom = '40px';
      cButton.style.lineHeight = '40px';
      cButton.style.padding = '0';
      cButton.style.background = '#E5451F';
      cButton.style.color = '#fff';
      cButton.style.fontSize = '15px';
      cButton.style.textAlign = 'center';
      cButton.style.outline = 'none';
      cButton.style.zIndex = '99999';
      cButton.style.cursor = 'pointer';
      cButton.style.borderRadius = '24px';
      cButton.style.left = 'calc(50% - 100px)';
      cButton.style.height = '40px';
      cButton.style.width = '200px';
      if (accountDetails?.zonePlus?.showTutorial) {
        cButton.textContent = 'Odbierz punkty';
      } else {
        cButton.textContent = 'Odbierz kupon';
      }
      cButton.addEventListener( 'click', () => {

        currentSession.end();
        navigate('/skaner/kupon', { replace: true })

      } );

      if (location === 'success' && (accountDetails?.zonePlus?.prize?.available || accountDetails?.zonePlus?.showTutorial)) {
        overlay.appendChild( cButton );
      } 

      sessionInit.optionalFeatures.push( 'dom-overlay' );
      sessionInit.domOverlay = { root: overlay };

      navigator.xr.requestSession( 'immersive-ar', sessionInit ).then( onSessionStarted );

      async function onSessionStarted( session ) {

        session.addEventListener( 'end', onSessionEnded );
    
        renderer.xr.setReferenceSpaceType( 'local' );
    
        await renderer.xr.setSession( session );
    
        sessionInit.domOverlay.root.style.display = '';
    
        currentSession = session
    
        animate()
    
      }
    
      function onSessionEnded( /*event*/ ) {
    
        currentSession.removeEventListener( 'end', onSessionEnded );
    
        sessionInit.domOverlay.root.style.display = 'none';
    
        currentSession = null;
        renderer.setAnimationLoop(null)
        setShowIntro(true)
        navigate('/skaner', { replace: true })
      }

    } else {

      currentSession.end();
      navigate('/skaner', { replace: true })

    }
  }

  const start3D = () => {
    controls = new DeviceOrientationControls( camera );
    controls.update();
    animate()
    setTimeout(()=>setSuccess(true), 500)
  }

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "environment"
  };

  return (
    <>
    { renderer?.xr?.isPresenting ? null : 
      <Webcam className='camera' audio={false}
        height={720}
        videoConstraints={videoConstraints}/>
    }
      <div
        style={{ height: window.innerHeight, width: window.innerWidth, position: "relative" }}
        className='canvas-ar-container'
        ref={refContainer}
      >
      </div>
      <div ref={refButton} style={{ position: 'absolute', bottom: 40, width: '100%'}}></div>
      <Intro onClose={()=>{ checkAR(); setShowIntro(false) }} playAnimate={ showIntro } step={route}/>
      { showIntro === false ?
        <div className="code-btn-button-wrap" style={{zIndex: "9999"}}>
          <div className="button-component">
            <div onClick={()=>navigate('/skaner/kupon', { replace: true })} className="code-btn">Dalej</div>
          </div>
        </div> : null }
    </>
  );
}

export default ARComponent;