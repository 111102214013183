import React, { useEffect, useState } from "react"
import { useLocation, NavLink } from "react-router-dom"
import "./style.scss"

export default function NotFound () {
  const { pathname } = useLocation()
  const [show, setShow] = useState(false)

  useEffect(() => {
    if(pathname === "/skaner" || pathname === "/mapa" || pathname === "/skaner/" || pathname === "/mapa/") {
      setShow(true)
    } else {
      setShow(false)
    }
  }, [pathname])

  return (
    <ul className={ (show ? "switch show " : "switch ") + pathname.replace("/", "") }>
      <li>
        <NavLink to="/skaner">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <rect width="24" height="24" rx="12" fill="#50504F"/>
            <path d="M7.23103 7.84599C7.23103 7.50621 7.5062 7.23104 7.84597 7.23104H9.38448C9.72426 7.23104 10 6.9553 10 6.61552C10 6.27574 9.72426 6 9.38448 6L7.84597 6C6.82666 6 6 6.82666 6 7.84597L6 9.38448C6 9.72426 6.27574 10 6.61552 10C6.9553 10 7.23104 9.72426 7.23104 9.38448L7.23103 7.84599Z" fill="#F6B900"/>
            <path d="M14.6155 6C14.2757 6 14 6.27574 14 6.61552C14 6.95529 14.2757 7.23104 14.6155 7.23104H16.154C16.4938 7.23104 16.769 7.5062 16.769 7.84598V9.38448C16.769 9.72426 17.0447 10 17.3845 10C17.7243 10 18 9.72426 18 9.38448V7.84598C18 6.82667 17.1733 6.00001 16.154 6.00001L14.6155 6Z" fill="#F6B900"/>
            <path d="M7.23104 14.6155C7.23104 14.2757 6.9553 14 6.61552 14C6.27574 14 6 14.2757 6 14.6155V16.154C6 17.1733 6.82666 18 7.84597 18H9.38448C9.72426 18 10 17.7243 10 17.3845C10 17.0447 9.72426 16.769 9.38448 16.769H7.84597C7.5062 16.769 7.23103 16.4938 7.23103 16.154L7.23104 14.6155Z" fill="#F6B900"/>
            <path d="M17.9998 14.6155C17.9998 14.2757 17.7509 14 17.4441 14C17.1373 14 16.8884 14.2757 16.8884 14.6155V16.154C16.8884 16.4938 16.6399 16.769 16.3332 16.769H15.2217C14.9149 16.769 14.6665 17.0447 14.6665 17.3845C14.6665 17.7243 14.9149 18 15.2217 18H16.3332C17.2535 18 17.9998 17.1733 17.9998 16.154L17.9998 14.6155Z" fill="#F6B900"/>
          </svg>
          <span>Skaner</span>
        </NavLink>
      </li>
      <li>
        <NavLink to="/mapa">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <rect width="24" height="24" rx="12" fill="#2F2F2F"/>
            <path d="M12 6.37988C14.4853 6.37988 16.5 8.3946 16.5 10.8799C16.5 11.8512 16.2241 12.6759 15.6556 13.5209C15.3319 14.002 14.9339 14.4717 14.2883 15.161C14.1109 15.3504 13.4329 16.0641 13.4337 16.0633C13.0674 16.4504 12.7741 16.7665 12.4778 17.0956L12 17.6266L11.5222 17.0956C11.2259 16.7665 10.9326 16.4504 10.5663 16.0633C10.5671 16.0641 9.88914 15.3504 9.71174 15.161C9.06612 14.4717 8.66813 14.002 8.34441 13.5209C7.77588 12.6759 7.5 11.8512 7.5 10.8799C7.5 8.3946 9.51472 6.37988 12 6.37988ZM12 7.6656C10.2248 7.6656 8.78571 9.10468 8.78571 10.8799C8.78571 11.5826 8.97932 12.1614 9.41113 12.8032C9.68711 13.2133 10.0481 13.6393 10.6502 14.2821C10.8239 14.4676 11.4982 15.1775 11.5003 15.1797C11.681 15.3708 11.8447 15.5452 12 15.7127C12.1553 15.5452 12.319 15.3708 12.4997 15.1797C12.5018 15.1775 13.1761 14.4676 13.3498 14.2821C13.9519 13.6393 14.3129 13.2133 14.5889 12.8032C15.0207 12.1614 15.2143 11.5826 15.2143 10.8799C15.2143 9.10468 13.7752 7.6656 12 7.6656ZM12 8.95131C13.0651 8.95131 13.9286 9.81476 13.9286 10.8799C13.9286 11.945 13.0651 12.8085 12 12.8085C10.9349 12.8085 10.0714 11.945 10.0714 10.8799C10.0714 9.81476 10.9349 8.95131 12 8.95131ZM12 10.237C11.645 10.237 11.3571 10.5248 11.3571 10.8799C11.3571 11.2349 11.645 11.5227 12 11.5227C12.355 11.5227 12.6429 11.2349 12.6429 10.8799C12.6429 10.5248 12.355 10.237 12 10.237Z" fill="white"/>
          </svg>
          <span>Mapa</span>
        </NavLink>
      </li>
    </ul>
  )
}
