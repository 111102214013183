import './style.scss';
import Webcam from "react-webcam";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import Gift from "./img/gift.svg"
import Neo from "./img/neo-logo.svg"
import Scan from "./img/scan.svg"
import Arrow from "./img/arrow.svg"
import { getAccountDetails, useUserDispatch, useUserState } from '../../UserContext';
import _ from 'lodash'

const Tutorial = () => {
    const navigate = useNavigate()
    const [step, setStep] = useState(0)
    const [animate, setAnimate] = useState("")
    
    const steps = [
        {
            title: "Skanuj",
            description: "Skieruj aparat na produkt.<br/>Skanuj paczkę neo™ lub logo glo™.<br/><span>Pamiętaj, żeby na paczce<br/>widoczne było logo neo™</span>",
            icon: Scan
        },
        {
            title: "Poczuj magię",
            description: "Odkryj magię po zeskanowaniu<br/>oraz zbierz darmowe nagrody.<br/><span>Pamiętaj, żeby na paczce<br/>widoczne było logo neo™</span>",
            icon: Gift
        },
        {
            title: "Pierwsza nagroda<br/>w Zone+",
            description: "Zdobądź jednorazową nagrodę skanując<br/>paczkę neo™ lub logo glo™ w domowym<br/>zaciszu<br/><span>Pamiętaj, żeby na paczce<br/>widoczne było logo neo™</span>",
            icon: Neo
        }
    ]

    useEffect(() => {
        setTimeout(() => { setAnimate("animate") }, 800)
    }, [])

    return (
        <div className={ 'bottom-modal tutorial ' + animate }>
            <div className='content'>
                <img className='icon' src={ steps[step].icon } alt=""/>
                <h3 dangerouslySetInnerHTML={{ __html: steps[step].title }}></h3>
                <p dangerouslySetInnerHTML={{ __html: steps[step].description }}></p>
                <div className="paginate">
                    <span>{step+1}</span>
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 29L29.2843 0.715726" stroke="white"></path></svg>
                    <span>{3}</span>
                </div>
            </div>
            <div className="button-wrap">
                <div className="button-component">
                    <button className='next' onClick={ () => {
                        if(step < 2) {
                            setStep(step+1)
                        } else {
                            setAnimate("")
                            setTimeout(() => { navigate("/skaner") }, 500)
                        }
                    } }>Dalej</button>
                </div>
            </div>
        </div>
    )
}

const CameraPrompt = () => {
    const [animate, setAnimate] = useState("")
    
    const steps = [
        {
            title: "Ciemno tu!",
            description: "Zezwól na używanie aparatu, aby skorzystać ze skanera Zone+."
        }
    ]

    useEffect(() => {
        setTimeout(() => { setAnimate("animate") }, 800)
    }, [])

    return (
        <div className={ 'bottom-modal full ' + animate }>
            <div className='content'>
                <h3 dangerouslySetInnerHTML={{ __html: steps[0].title }}></h3>
                <p dangerouslySetInnerHTML={{ __html: steps[0].description }}></p>
            </div>
        </div>
    )
}

const Intro = () => {
    const { accountDetails } = useUserState()
    const navigate = useNavigate()
    const step = accountDetails?.zonePlus?.prize?.available ? 1 : 0
    const [animate, setAnimate] = useState("")

    const steps = [
        {
            title: "Co tydzień nowa nagroda!",
            description: "Zgarniaj kupony skanując paczkę neo™ lub logo&nbsp;glo™ w&nbsp;jednym z&nbsp;punktów biorących udział w akcji.<br/><span>Pamiętaj, żeby na paczce widoczne było logo neo™</span>"
        },
        {
            title: "Na mieście jest nagroda!",
            description: "Wpadnij do wybranego miejsca<br/>biorącego udział w akcji i odbierz kupon<br/>skanując paczkę neo™ lub logo glo™.<br/><span>Pamiętaj, żeby na paczce<br/>widoczne było logo neo™</span>"
        }
    ]

    useEffect(() => {
        setTimeout(() => { setAnimate("animate") }, 800)
    }, [])

    return (
        <div className={ 'bottom-modal intro ' + animate }>
            <div className='content'>
                <h3 dangerouslySetInnerHTML={{ __html: steps[step].title }}></h3>
                <p dangerouslySetInnerHTML={{ __html: steps[step].description }}></p>
            </div>
            <div className="button-wrap">
                <div className="button-component">
                    <button className='next' onClick={ () => {
                        setAnimate("")
                        setTimeout(() => { navigate("/skaner") }, 500)
                    } }>Dalej</button>
                </div>
            </div>
        </div>
    )
}

function Welcome() {
  const dispatch = useUserDispatch()
  const { accountDetails } = useUserState()
  const [cameraPermission, setCameraPermission] = useState("")

  useEffect(() => {
    getAccountDetails(dispatch)
    // navigator.permissions.query({ name: 'camera' }).then((permissionStatus) => {
    //     console.log(`camera permission state is ${permissionStatus.state}`);
    //     setCameraPermission(permissionStatus.state)
    //     permissionStatus.onchange = () => {
    //       console.log(`camera permission state has changed to ${permissionStatus.state}`);
    //       setCameraPermission(permissionStatus.state)
    //     };
    //   });
    //eslint-disable-next-line
  }, [])

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "environment"
  };

  return (
    <div className="welcome-page">
        {/* <p>{cameraPermission}</p> */}
      <Webcam key={cameraPermission} className='camera' audio={false}
        height={720}
        videoConstraints={videoConstraints}/>
      {/* { cameraPermission === "granted" ? <> */}
        { accountDetails?.zonePlus?.tutorial?.available ? <Tutorial/> : <Intro/> }
      {/* </> : <>
        <CameraPrompt/>
      </> } */}
    </div>
  );
}

export default Welcome;
