export const places = [
    {
        name: "Test DO",
        address: "Lorem ipsum",
        lat: 52.23786779795501,
        lng: 21.008535712627193,
        award_ident: "",
        hidden: true
    },
    {
        name: "Test PJ",
        address: "Lorem ipsum",
        lat: 52.15184228242849,
        lng: 21.045489926811335,
        award_ident: "",
        hidden: true
    },
    {
        name: "Test ul. Poematu",
        address: "Lorem ipsum",
        lat: 52.15746083897908,
        lng: 21.199235267760685,
        award_ident: "",
        hidden: true
    },
    {
        name: "Test DOT2",
        address: "Lorem ipsum",
        lat: 54.6930105,
        lng: 18.6786364,
        award_ident: "",
        hidden: true
    },
    {
        name: "Test BAT",
        address: "Lorem ipsum",
        lat: 52.1891513,
        lng: 20.9457334,
        award_ident: "",
        hidden: true
    },
    {
        name: "Test MG",
        address: "Lorem ipsum",
        lat: 51.473889677911295,
        lng: 21.46151996917285,
        award_ident: "",
        hidden: true
    },
    {
        name: "Test PJ",
        address: "Lorem ipsum",
        lat: 52.1517449,
        lng: 21.0453354,
        award_ident: "",
        hidden: true
    },
    {
        name: "Test Kierbedzia",
        address: "Lorem ipsum",
        lat: 52.1993271,
        lng: 21.0444946,
        award_ident: "",
        hidden: true
    },
    {
        name: "Test Powązki",
        address: "Lorem ipsum",
        lat: 52.26444245804802,
        lng: 20.52006181776937,
        award_ident: "",
        hidden: true
    },
    {
        name: "Test Biuro",
        address: "Grójecka 45",
        lat: 52.21640085358147,
        lng: 20.981720763912502,
        award_ident: "",
        hidden: true
    },
    {
        name: "Test Kajetany",
        address: "Lorem ipsum",
        lat: 52.0756747,
        lng: 20.8275534,
        award_ident: "",
        hidden: true
    },
    {
        name: "Test MS",
        address: "Lorem ipsum",
        lat: 52.432240048797524,
        lng: 16.932709204768173,
        award_ident: "",
        hidden: true
    },
    {
        name: "Test DT",
        address: "Lorem ipsum",
        lat: 54.6933000,
        lng: 18.6798538,
        award_ident: "",
        hidden: true
    },
    {
        name: "Test DT2",
        address: "Lorem ipsum",
        lat: 52.12022749713504,
        lng: 20.90654504863692,
        award_ident: "",
        hidden: true
    },
    {
        name: "Test MS2",
        address: "Lorem ipsum",
        lat: 53.161790250514336,
        lng: 22.078748264011175,
        award_ident: "",
        hidden: true
    },
    {
        name: "Testy",
        address: "Lorem ipsum",
        lat: 52.1889001,
        lng: 20.9465571,
        award_ident: "",
        hidden: true
    },
    {
        name: "Test PM",
        address: "Lorem ipsum",
        lat: 52.280880,
        lng: 21.051665,
        award_ident: "",
        hidden: true
    },
    {
        name: "Test EW",
        address: "Lorem ipsum",
        lat: 51.744494700000004,
        lng: 19.44515209807713,
        award_ident: "",
        hidden: true
    },
    {
        name: "Test MS",
        address: "Lorem ipsum",
        lat: 52.409907363994776,
        lng: 16.939381748100686,
        award_ident: "",
        hidden: true
    },
    {
        name: "Test L",
        address: "Lorem ipsum",
        lat: 51.1114762,
        lng: 17.0289432,
        award_ident: "",
        hidden: true
    },
    {
        name: "FABRYKA NORBLINA",
        address: "Żelazna 51/53, 00-841 Warszawa",
        lat: 52.232139430438785,
        lng: 20.990499013727575,
        award_ident: ""
    },
    {
        name: "LUNAPARK (Hocki Klocki)",
        address: "Wał Miedzeszyński 407, 05-077 Warszawa",
        lat: 52.23236899317026,
        lng: 21.048445177346125,
        award_ident: ""
    },
    {
        name: "ZAGRYWKI ",
        address: "Nowy Świat 4A, 00-496 Warszawa",
        lat: 52.23046330828367,
        lng: 21.02219434521303,
        award_ident: ""
    },
    {
        name: "WIR (+ Schodki)",
        address: "Generała George’a Smitha Pattona, 05-077 Warszawa",
        lat: 52.236756124494185,
        lng: 21.03725383955934,
        award_ident: ""
    },
    {
        name: "LEVEL 27",
        address: "al. Jerozolimskie 123A, 02-017 Warszawa",
        lat: 52.224882545177636,
        lng: 20.991373655997975,
        award_ident: ""
    },
    {
        name: "DZIEŃ I NOC (HALA MIROWSKA ) ",
        address: "Plac Mirowski 1, 00-138 Warszawa",
        lat: 52.2388950779177,
        lng: 20.996656393952346,
        award_ident: ""
    },
    {
        name: "Explosion",
        address: "Wał Miedzeszyński 646, 03-944 Warszawa",
        lat: 52.2215325314053,
        lng: 21.084864700754242,
        award_ident: ""
    },
    {
        name: "NOCNY MARKET",
        address: "Towarowa 3, 01-210 Warszawa",
        lat: 52.22525269724436,
        lng: 20.982626726205655,
        award_ident: ""
    },
    {
        name: "SEN ",
        address: "Wioślarska 6, 00-411 Warszawa",
        lat: 52.2328654723158,
        lng: 21.04013385391317,
        award_ident: ""
    },
    {
        name: "Zielona Gęś",
        address: "al. Niepodległości 177, 02-555 Warszawa",
        lat: 52.210219573850594,
        lng: 21.00658495296954,
        award_ident: ""
    },
    {
        name: "Newonce",
        address: "Nowy Świat 6/12, 00-497 Warszawa",
        lat: 52.23088514658553,
        lng: 21.02230311108262,
        award_ident: ""
    },
    {
        name: "LULU BAR",
        address: "Poznańska 7, Róg Wilczej, 00-680 Warszawa",
        lat: 52.224031407508846,
        lng: 21.012296931523377,
        award_ident: ""
    },
    {
        name: "KUCHNIA ZOLIBORZ ",
        address: "Ludwika Rydygiera 8/lok. 20 B, 01-793 Warszawa",
        lat: 52.26082078228942,
        lng: 20.976241512931527,
        award_ident: ""
    },
    {
        name: "HANY BANY ",
        address: "Foksal, 05-800 Warszawa",
        lat: 52.23341407854201,
        lng: 21.019902357042696,
        award_ident: ""
    },
    {
        name: "PAWILON 31",
        address: "Nowy Świat 22/28/Pawilon 31, 00-373 Warszawa",
        lat: 52.23326285684511,
        lng: 21.02122399432704,
        award_ident: ""
    },
    {
        name: "SZPRYCER",
        address: "Nowy Świat 22/28 paw.7, 00-373 Warszawa",
        lat: 52.233184448083854,
        lng: 21.02053882782619,
        award_ident: ""
    },
    {
        name: "PIJALNIA WÓDKI I PIWA Nowy Świat",
        address: "Nowy Świat 22/28, 00-373 Warszawa",
        lat: 52.23234534024891,
        lng: 21.02001486639331,
        award_ident: ""
    },
    {
        name: "PIJALNIA WÓDKI I PIWA Parkingowa",
        address: "Nowogrodzka 27, 00-511 Warszawa",
        lat: 52.22905603002969,
        lng: 21.013967513941903,
        award_ident: ""
    },
    {
        name: "PIJALNIA WÓDKI I PIWA Pawilony",
        address: "Pawilon 2/3, Warszawa",
        lat: 52.23318082455032,
        lng: 21.020061587898113,
        award_ident: ""
    },
    {
        name: "Mazo ",
        address: "Mazowiecka 9, 05-077 Warszawa",
        lat: 52.23709720336124,
        lng: 21.01304022684208,
        award_ident: ""
    },
    {
        name: "WARMUT x GRAM ",
        address: "Marszałkowska 45/49, 00-648 Warszawa",
        lat: 52.22084879350547,
        lng: 21.017054909172522,
        award_ident: ""
    },
    {
        name: "AIOLI CHMIELNA",
        address: "Chmielna 26, 05-800 Warszawa",
        lat: 52.23222760032299,
        lng: 21.013523089249542,
        award_ident: ""
    },
    {
        name: "Bulldog Pud",
        address: "Aleje Jerozolimskie, Krucza 42, Warszawa",
        lat: 52.230991552987916,
        lng: 21.015975586537415,
        award_ident: ""
    },
    {
        name: "WILD BEEF",
        address: "Bulwar Zbigniewa Religi 3, 03-001 Warszawa",
        lat: 52.26139724921489,
        lng: 21.006951355281764,
        award_ident: ""
    },
    {
        name: "RITUAL",
        address: "Mazowiecka 12, 00-048 Warszawa",
        lat: 52.23771110581073,
        lng: 21.013225090928348,
        award_ident: ""
    },
    {
        name: "Zanzibar ",
        address: "Żurawia 32/34, 00-503 Warszawa",
        lat: 52.228387132255776,
        lng: 21.013863658206763,
        award_ident: ""
    },
    {
        name: "Klar",
        address: "Krakowskie Przedmieście 41, 00-070 Warszawa",
        lat: 52.2444403267593,
        lng: 21.01394701341806,
        award_ident: ""
    },
    {
        name: "RAJ",
        address: "Nowy Świat 22/28/Pawilon 25, 00-373 Warszawa",
        lat: 52.23290527018554,
        lng: 21.0200321365972,
        award_ident: ""
    },
    {
        name: "Setka",
        address: "Świętokrzyska 14, 00-050 Warszawa",
        lat: 52.23656753891226,
        lng: 21.01428246306183,
        award_ident: ""
    },
    {
        name: "Moderne",
        address: "Zajęcza 2b, 00-351 Warszawa",
        lat: 52.2393161512938,
        lng: 21.02878334709791,
        award_ident: ""
    },
    {
        name: "Bardzo Bardzo ",
        address: "Nowogrodzka 11, 00-513 Warszawa",
        lat: 52.22937849974472,
        lng: 21.017899154159448,
        award_ident: ""
    },
    {
        name: "HULAKULA",
        address: "Jagiellońska 82B, 03-301 Warszawa",
        lat: 52.27343410799418,
        lng: 21.016994124578478,
        award_ident: ""
    },
    {
        name: "MINISTERSTWO Śledzia i Wódki Nowogrodzka",
        address: "Nowogrodzka 22, 00-511 Warszawa",
        lat: 52.22930339825888,
        lng: 21.01425845047822,
        award_ident: ""
    },
    {
        name: "Bar A Boo",
        address: "plac Europejski 5, 00-844 Warszawa",
        lat: 52.23264544387325,
        lng: 20.985286495481894,
        award_ident: ""
    },
    {
        name: "Pub Student",
        address: "Ludwika Waryńskiego 12, 00-631 Warszawa",
        lat: 52.216257743926334,
        lng: 21.016249038869546,
        award_ident: ""
    },
    {
        name: "Remont Klub",
        address: "Ludwika Waryńskiego 12a, 00-636 Warszawa",
        lat: 52.21683431064438,
        lng: 21.01661265376928,
        award_ident: ""
    },
    {
        name: "Gruba kaśka ",
        address: "aleja \"Solidarności\" 68a, 00-240 Warszawa",
        lat: 52.24497052223353,
        lng: 21.001759484668828,
        award_ident: ""
    },
    {
        name: "Jack's",
        address: "Bracka 18, 00-028 Warszawa",
        lat: 52.23182802432288,
        lng: 21.016975135545884,
        award_ident: ""
    },
    {
        name: "13 Rogów",
        address: "Nowy Świat 32, 00-373 Warszawa",
        lat: 52.23359602094708,
        lng: 21.01958009554033,
        award_ident: ""
    },
    {
        name: "Bar A Boo",
        address: "Gołębia 6, Poznań",
        lat: 52.40667430767142,
        lng: 16.93336182215646,
        award_ident: ""
    },
    {
        name: "Harmonia Grill & Chill",
        address: "Dominikańska 9, Poznań",
        lat: 52.40993565387063,
        lng: 16.935973267512964,
        award_ident: ""
    },
    {
        name: "Hashtag",
        address: "Wrocławska 7, Poznań",
        lat: 52.40700065549293,
        lng: 16.933384238842628,
        award_ident: ""
    },
    {
        name: "Hashtag Chill",
        address: "Św. Marcin 31, Poznań",
        lat: 52.4061875008211,
        lng: 16.925870127203982,
        award_ident: ""
    },
    {
        name: "Hashtag Rap & More",
        address: "Wrocławska 25, Poznań",
        lat: 52.406055510861066,
        lng: 16.933050123471148,
        award_ident: ""
    },
    {
        name: "Hokus Pokus",
        address: "Wrocławska 23, Poznań",
        lat: 52.40611123805592,
        lng: 16.93303934295512,
        award_ident: ""
    },
    {
        name: "Kultowa",
        address: "Wrocławska 16, Poznań",
        lat: 52.40628022261718,
        lng: 16.932679203019536,
        award_ident: ""
    },
    {
        name: "Miasto",
        address: "Szewska 20, Poznań",
        lat: 52.40919613237603,
        lng: 16.936551844153616,
        award_ident: ""
    },
    {
        name: "MINISTERSTWO Śledzia i Wódki",
        address: "Stary Rynek 90, Poznań",
        lat: 52.40898909124061,
        lng: 16.933457548349566,
        award_ident: ""
    },
    {
        name: "Mozaika",
        address: "Wrocławska 14, Poznań",
        lat: 52.40644469738725,
        lng: 16.93288355276222,
        award_ident: ""
    },
    {
        name: "PIJALNIA WÓDKI I PIWA Rynek",
        address: "Stary Rynek 85, Poznań",
        lat: 52.40899123469173,
        lng: 16.932844792792757,
        award_ident: ""
    },
    {
        name: "PIJALNIA WÓDKI I PIWA Wrocławska",
        address: "Wrocławska 8, Poznań",
        lat: 52.4067842463773,
        lng: 16.932862088154067,
        award_ident: ""
    },
    {
        name: "Pinokio",
        address: "Sikorskiego Władyslawa 29, Szczecin",
        lat: 53.42233547337672,
        lng: 14.530163727537493,
        award_ident: ""
    },
    {
        name: "Pub Jakubek",
        address: "Jaskółcza, Poznań",
        lat: 52.40624842344077,
        lng: 16.932467804851143,
        award_ident: ""
    },
    {
        name: "The Ptak i Pies",
        address: "Edmunda Baluki 16 /LU1, Szczecin",
        lat: 53.42917240835879,
        lng: 14.545252067876682,
        award_ident: ""
    },
    {
        name: "Akademia",
        address: "Grunwaldzka 67, 50-357 Wrocław",
        lat: 51.11465308666442,
        lng: 17.066759082634192,
        award_ident: ""
    },
    {
        name: "AMBASADA",
        address: "Świętego Mikołaja 8/11, 50-125 Wrocław",
        lat: 51.11159648552824,
        lng: 17.029285037951784,
        award_ident: ""
    },
    {
        name: "BROWAR KSIĄŻĘCY",
        address: "Dworcowa 9, 59-220 Legnica",
        lat: 51.21254973635179,
        lng: 16.16802009613528,
        award_ident: ""
    },
    {
        name: "BUŁKA Z MASŁEM",
        address: "Pawła Włodkowica 8A, 50-072 Wrocław",
        lat: 51.108209671863136,
        lng: 17.024290549868407,
        award_ident: ""
    },
    {
        name: "Ceregiele 2.0",
        address: "Ofiar Oświęcimskich 17, 50-069 Wrocław",
        lat: 51.108520485075786,
        lng: 17.03172018263373,
        award_ident: ""
    },
    {
        name: "CUBANA",
        address: "Rynek 33, 59-220 Legnica",
        lat: 51.20799116989075,
        lng: 16.160420502345456,
        award_ident: ""
    },
    {
        name: "DOCTOR'S",
        address: "Świętego Mikołaja 8, 50-125 Wrocław",
        lat: 51.1115152175727,
        lng: 17.029137644698487,
        award_ident: ""
    },
    {
        name: "DOJUTRA Ofiar Oświęcimskich",
        address: "Ofiar Oświęcimskich 19, 50-069 Wrocław",
        lat: 51.10846032825723,
        lng: 17.031812647144466,
        award_ident: ""
    },
    {
        name: "KOMBINAT",
        address: "Plac Solny 6/7A, 50-061 Wrocław",
        lat: 51.10978697546991,
        lng: 17.028808751089457,
        award_ident: ""
    },
    {
        name: "LOT KURY",
        address: "Ofiar Oświęcimskich 17, 50-438 Wrocław",
        lat: 51.10854540111481,
        lng: 17.03162285352835,
        award_ident: ""
    },
    {
        name: "MINISTERSTWO Śledzia i Wódki",
        address: "Rynek 34, 59-220 Legnica",
        lat: 51.208137868975484,
        lng: 16.160137341199164,
        award_ident: ""
    },
    {
        name: "MINISTERSTWO Śledzia i Wódki",
        address: "Rynek 32, 46-020 Opole",
        lat: 50.66795286673323,
        lng: 17.922453224485682,
        award_ident: ""
    },
    {
        name: "MINISTERSTWO Śledzia i Wódki Rynek",
        address: "Rynek 7, 50-106 Wrocław",
        lat: 51.110137629316164,
        lng: 17.030245321671742,
        award_ident: ""
    },
    {
        name: "MINISTERSTWO Śledzia i Wódki Rzeźnicza",
        address: "Rzeźnicza 34, 50-130 Wrocław",
        lat: 51.11040984586785,
        lng: 17.0276884996384,
        award_ident: ""
    },
    {
        name: "NADODRZE",
        address: "Bolesława Drobnera 26a, 50-257 Wrocław",
        lat: 51.11866636778183,
        lng: 17.038458911199466,
        award_ident: ""
    },
    {
        name: "NIETOTA",
        address: "Kazimierza Wielkiego 50, 52-007 Wrocław",
        lat: 51.107734838872624,
        lng: 17.03162735839743,
        award_ident: ""
    },
    {
        name: "PIJALNIA WÓDKI I PIWA",
        address: "Książąt Opolskich 2/6, 45-006 Opole",
        lat: 50.66922459500753,
        lng: 17.922348844442837,
        award_ident: ""
    },
    {
        name: "PIJALNIA WÓDKI I PIWA",
        address: "Zamkowa 8, 59-220 Legnica",
        lat: 51.21021136140608,
        lng: 16.164451767701806,
        award_ident: ""
    },
    {
        name: "PIJALNIA WÓDKI I PIWA Mikołaja",
        address: "Świętego Mikołaja 11, 50-125 Wrocław",
        lat: 51.11167635540689,
        lng: 17.028710167292513,
        award_ident: ""
    },
    {
        name: "PIJALNIA WÓDKI I PIWA Rynek-Ratusz",
        address: "Rynek 13/14, 50-101 Wrocław",
        lat: 51.11055795899284,
        lng: 17.03232261146925,
        award_ident: ""
    },
    {
        name: "PLANTA BAR",
        address: "Najświętszej Marii Panny 14, 59-220 Legnica",
        lat: 51.20882632955693,
        lng: 16.16403775380574,
        award_ident: ""
    },
    {
        name: "POD LATARNIAMI",
        address: "Ruska 3/4, 50-512 Wrocław",
        lat: 51.11008863939232,
        lng: 17.02842901828942,
        award_ident: ""
    },
    {
        name: "Pub Maska Laboratorium",
        address: "Rynek 4/6, 45-015 Opole",
        lat: 50.668343579606265,
        lng: 17.921771765140505,
        award_ident: ""
    },
    {
        name: "Schody Donikąd",
        address: "Plac Solny 13, 50-061 Wrocław",
        lat: 51.10908871699212,
        lng: 17.028686295812623,
        award_ident: ""
    },
    {
        name: "Setka",
        address: "Kazimierza Wielkiego 50, 50-077 Wrocław",
        lat: 51.109513151981496,
        lng: 17.03064573222422,
        award_ident: ""
    },
    {
        name: "SUROWIEC",
        address: "Ruska 46A, 50-079 Wrocław",
        lat: 51.11047765226968,
        lng: 17.024490455375908,
        award_ident: ""
    },
    {
        name: "XIII IGIEŁ",
        address: "Igielna 13, 50-438 Wrocław",
        lat: 51.11161537037547,
        lng: 17.031594497705235,
        award_ident: ""
    },
    {
        name: "107 Pub",
        address: "Ogarna 107, 80-826 Gdańsk",
        lat: 54.34840222275559,
        lng: 18.651390231000402,
        award_ident: ""
    },
    {
        name: "Aioli",
        address: "Partyzantów 6, 80-254 Gdańsk",
        lat: 54.37764970956401,
        lng: 18.606273355498796,
        award_ident: ""
    },
    {
        name: "Bar Max",
        address: "Grunwaldzka 1-3, Sopot",
        lat: 54.4449226375863,
        lng: 18.569608813186576,
        award_ident: ""
    },
    {
        name: "Cafe Szafa",
        address: "Podmurze 2, 80-835 Gdańsk",
        lat: 54.35209817486636,
        lng: 18.650502979776068,
        award_ident: ""
    },
    {
        name: "Cooltura Club",
        address: "Okopowa 7, 80-748 Gdańsk",
        lat: 51.208137868975484,
        lng: 16.160137341199164,
        award_ident: ""
    },
    {
        name: "Dobranocka",
        address: "Prosta 38, 10-029 Olsztyn",
        lat: 53.775634574483085,
        lng: 20.47542771275997,
        award_ident: ""
    },
    {
        name: "Gorzko Gorzko",
        address: "Armii Krajowej 111, Sopot",
        lat: 54.44432571052594,
        lng: 18.55561725551592,
        award_ident: ""
    },
    {
        name: "Gorzko Gorzko",
        address: "Korzenna 33/35, 80-851 Gdańsk",
        lat: 54.35403857280341,
        lng: 18.648076055497715,
        award_ident: ""
    },
    {
        name: "Havana In Gdansk",
        address: "Świętego Ducha 68, 80-834 Gdańsk",
        lat: 54.35021314818994,
        lng: 18.655033511320386,
        award_ident: ""
    },
    {
        name: "Jagspot",
        address: "Świętego Ducha 14b, 80-834 Gdańsk",
        lat: 54.35112006294751,
        lng: 18.650501311320447,
        award_ident: ""
    },
    {
        name: "Kofeina (COFFEINA)",
        address: "Staromiejska 13, 11-041 Olsztyn",
        lat: 53.77744991429498,
        lng: 20.477015086512353,
        award_ident: ""
    },
    {
        name: "Mięta",
        address: "Świętej Barbary 9, 10-022 Olsztyn",
        lat: 53.776619621286095,
        lng: 20.476906636643523,
        award_ident: ""
    },
    {
        name: "MINISTERSTWO Śledzia i Wódki",
        address: "Targ Drzewny 3/7 80-886 Gdańsk",
        lat: 54.35258375671161,
        lng: 18.647841326662217,
        award_ident: ""
    },
    {
        name: "PIJALNIA WÓDKI I PIWA",
        address: "Staromiejska 6, 10-018 Olsztyn",
        lat: 53.777035241066166,
        lng: 20.477113967276143,
        award_ident: ""
    },
    {
        name: "PIJALNIA WÓDKI I PIWA",
        address: "Długi Targ 35/38 80-830 Gdańsk",
        lat: 54.34852694355712,
        lng: 18.654482957345554,
        award_ident: ""
    },
    {
        name: "Poza Space",
        address: "Marii Rodziewiczówny 8, 11-041 Olsztyn",
        lat: 53.77540500927306,
        lng: 20.476656810220607,
        award_ident: ""
    },
    {
        name: "Pub Polskie Kino",
        address: "Szeroka 97, 80-835 Gdańsk",
        lat: 54.35143510284823,
        lng: 18.654417682484986,
        award_ident: ""
    },
    {
        name: "Pułapka",
        address: "Straganiaska 2, 80-837 Gdańsk",
        lat: 54.352745592428704,
        lng: 18.653126655497697,
        award_ident: ""
    },
    {
        name: "Republika Restauracja",
        address: "Długie Pobrzeże 8, 80-833 Gdańsk",
        lat: 54.349100571551105,
        lng: 18.656308913168402,
        award_ident: ""
    },
    {
        name: "Stacja FOOD HALL",
        address: "Jana Kilińskiego 4, 80-266 Gdańsk",
        lat: 54.38307588246734,
        lng: 18.605265768992684,
        award_ident: ""
    },
    {
        name: "Szafa",
        address: "plac Jedności Słowiańskiej 9, 10-039 Olsztyn",
        lat: 53.77705842122112,
        lng: 20.4778291184195,
        award_ident: ""
    },
    {
        name: "Sztos",
        address: "Jozefa Bema 1A, Sopot",
        lat: 54.44382310821237,
        lng: 18.567710990820498,
        award_ident: ""
    },
    {
        name: "Browar Port Gdynia",
        address: "Bulwar Nadmorski im.Feliksa Nowowiejskiego 2, 81-371 Gdynia",
        lat: 54.5140435962851,
        lng: 18.550001082506753,
        award_ident: ""
    },
    {
        name: "Contrast",
        address: "Bulwar Nadmorski im.Feliksa Nowowiejskiego, 81-374 Gdynia",
        lat: 54.51576495587471,
        lng: 18.54903890147306,
        award_ident: ""
    },
    {
        name: "Domówka",
        address: "Juliana Tuwima 17, 76-200 Słupsk",
        lat: 54.46471076465595,
        lng: 17.01904866901066,
        award_ident: ""
    },
    {
        name: "Fabryka Lloyda",
        address: "Fordońska 156, 85-752 Bydgoszcz",
        lat: 53.1261058077084,
        lng: 18.083351798237725,
        award_ident: ""
    },
    {
        name: "Igranie w lochu",
        address: "Świętojańska 63, 81-391 Gdynia",
        lat: 54.51409606041717,
        lng: 18.540464487296873,
        award_ident: ""
    },
    {
        name: "Kadr Cafe",
        address: "Żeglarska 5, 87-100 Toruń",
        lat: 53.00862412068537,
        lng: 18.60586702439979,
        award_ident: ""
    },
    {
        name: "Los Chupitos",
        address: "Stary Rynek 27, 85-105 Bydgoszcz",
        lat: 53.121606346299444,
        lng: 18.001116641223497,
        award_ident: ""
    },
    {
        name: "Niewinni Czarodzieje / NC 3.0",
        address: "plac Grunwaldzki 2, 81-372 Gdynia",
        lat: 54.51685113438435,
        lng: 18.543206911342182,
        award_ident: ""
    },
    {
        name: "NRD",
        address: "Browarna 6, 87-100 Toruń",
        lat: 53.0110070926008,
        lng: 18.61195963965996,
        award_ident: ""
    },
    {
        name: "PIJALNIA WÓDKI I PIWA",
        address: "Rynek Staromiejski 26/27, 87-100 Toruń",
        lat: 53.01104750432659,
        lng: 18.60440071545848,
        award_ident: ""
    },
    {
        name: "Plastelina",
        address: "Piastowska 21, 75-004 Koszalin",
        lat: 54.190897875898884,
        lng: 16.18951762587498,
        award_ident: ""
    },
    {
        name: "Podwórko",
        address: "Świętego Piotra 2, 81-347 Gdynia",
        lat: 54.524594931166995,
        lng: 18.546672655519377,
        award_ident: ""
    },
    {
        name: "Prywatka",
        address: "Stary Rynek 21, 85-105 Bydgoszcz",
        lat: 53.121831223333096,
        lng: 18.001110060835778,
        award_ident: ""
    },
    {
        name: "Pub Kubryk",
        address: "Podwale 3, 85-111 Bydgoszcz",
        lat: 53.12184737377706,
        lng: 18.002549568979422,
        award_ident: ""
    },
    {
        name: "Salut",
        address: "Świętojańska 11, 81-368 Gdynia",
        lat: 54.520367527132485,
        lng: 18.543211601482955,
        award_ident: ""
    },
    {
        name: "To2",
        address: "Długa 9, 85-034 Bydgoszcz",
        lat: 53.12154450695625,
        lng: 17.997301921496685,
        award_ident: ""
    },
    {
        name: "Trawa",
        address: "Stary Rynek 25, 85-105 Bydgoszcz",
        lat: 53.121718769160715,
        lng: 18.001135368979398,
        award_ident: ""
    },
    {
        name: "Wymówka",
        address: "Świętojańska 59, 81-391 Gdynia",
        lat: 54.514433604033606,
        lng: 18.540613406640485,
        award_ident: ""
    },
    {
        name: "Zakład?",
        address: "Gimnazjalna 8/1C, 85-007 Bydgoszcz",
        lat: 53.12744851105004,
        lng: 18.0079619545914,
        award_ident: ""
    },
    {
        name: "Bunga Bunga",
        address: "Lipowa 2/1 15-426 Białystok",
        lat: 53.13291747500703,
        lng: 23.157100268953897,
        award_ident: ""
    },
    {
        name: "CZARNY RYNEK",
        address: "Rynek Kościuszki 32 15-421 Białystok",
        lat: 53.1328551465153,
        lng: 23.157728343362233,
        award_ident: ""
    },
    {
        name: "GŁĘBOKIE GARDŁO",
        address: "Rynek Kościuszki 24 15-421 Białystok",
        lat: 53.13277722754875,
        lng: 23.158471626624525,
        award_ident: ""
    },
    {
        name: "GŁĘBOKIE GARDŁO II",
        address: "Józefa Marjańskiego 42, 15-369 Białystok",
        lat: 53.1299520785603,
        lng: 23.153484384295243,
        award_ident: ""
    },
    {
        name: "GWINT - Politechnika",
        address: "Zwierzyniecka 10, 15-351 Białystok",
        lat: 53.11879506497213,
        lng: 23.1515883824473,
        award_ident: ""
    },
    {
        name: "Jack Sparrow",
        address: "ul, Henryka Sienkiewicza 1/1/lok.2, 15-092 Białystok",
        lat: 53.13127428838115,
        lng: 23.160335426624528,
        award_ident: ""
    },
    {
        name: "Jaga Pizza & Bistro",
        address: "Lipowa 1, 15-424 Białystok",
        lat: 53.13271709218681,
        lng: 23.15700917080141,
        award_ident: ""
    },
    {
        name: "LITTLE HELL Lipowa",
        address: "Lipowa 4, 15-427 Białystok",
        lat: 53.133039354242584,
        lng: 23.15698009778931,
        award_ident: ""
    },
    {
        name: "MOTO PUB",
        address: "Młynowa 14, 15-404 Białystok",
        lat: 53.12931222852734,
        lng: 23.152316268953776,
        award_ident: ""
    },
    {
        name: "PIJALNIA WÓDKI I PIWA",
        address: "Lipowa 3/5, 15-424 Białystok",
        lat: 53.13225207173266,
        lng: 23.155747768953916,
        award_ident: ""
    },
    {
        name: "PRL Pub",
        address: "Suraska 2, 15-421 Białystok",
        lat: 53.13201018716877,
        lng: 23.157404140118505,
        award_ident: ""
    },
    {
        name: "PUB FICTION",
        address: "Suraska 1, 15-093 Białystok",
        lat: 53.131382325324125,
        lng: 23.157142311283135,
        award_ident: ""
    },
    {
        name: "Ratuszowy",
        address: "Lipowa 1, 15-424 Białystok",
        lat: 53.132635633876475,
        lng: 23.157020568953932,
        award_ident: ""
    },
    {
        name: "Rokoko 2.0",
        address: "Legionowa 14/16, 15-099 Białystok",
        lat: 53.13049107156992,
        lng: 23.158969459005984,
        award_ident: ""
    },
    {
        name: "Rokoko Cubano",
        address: "Legionowa 3, 15-004 Białystok",
        lat: 53.13002486688198,
        lng: 23.158966914024834,
        award_ident: ""
    },
    {
        name: "SAVONA RYNEK",
        address: "Rynek Kościuszki 8/1, 15-426 Białystok",
        lat: 53.13266238609415,
        lng: 23.159755340118522,
        award_ident: ""
    },
    {
        name: "SKY",
        address: "Henryka Sienkiewicza 3, 15-432 Białystok",
        lat: 53.13272512579619,
        lng: 23.160376108184412,
        award_ident: ""
    },
    {
        name: "SZKLANKI I TALERZE",
        address: "Rynek Kościuszki 32, 15-425 Białystok",
        lat: 53.13292453322726,
        lng: 23.157696897789293,
        award_ident: ""
    },
    {
        name: "USTA USTA",
        address: "Rynek Kościuszki 26, 15-426 Białystok",
        lat: 53.13286822265327,
        lng: 23.15829689778925,
        award_ident: ""
    },
    {
        name: "ZMIANA KLIMATU",
        address: "Warszawska 6, 15-063 Białystok",
        lat: 53.13551977342115,
        lng: 23.16679347080155,
        award_ident: ""
    },
    {
        name: "Galway Irish Pub & Steakhouse",
        address: "3 Maja 8, 35-030 Rzeszów",
        lat: 50.03704717739599,
        lng: 22.001212709650925,
        award_ident: ""
    },
    {
        name: "GROTA",
        address: "3 Maja 8, 35-232 Rzeszów",
        lat: 50.03697101866225,
        lng: 22.001235207202075,
        award_ident: ""
    },
    {
        name: "Hokus 2.0",
        address: "3 Maja 13, 35-030 Rzeszów",
        lat: 50.03622067202821,
        lng: 22.00135317109444,
        award_ident: ""
    },
    {
        name: "Hollywood",
        address: "Wesoła 28, 25-305 Kielce",
        lat: 50.87087989675704,
        lng: 20.6329785235746,
        award_ident: ""
    },
    {
        name: "Lord Jack",
        address: "Rynek 3/4, 35-064 Rzeszów",
        lat: 50.03702696925385,
        lng: 22.004266093871063,
        award_ident: ""
    },
    {
        name: "MINISTERSTWO Śledzia i Wódki Św. Leonarda 16/3",
        address: "Świętego Leonarda 16/3, 25-311 Kielce",
        lat: 50.87061584398024,
        lng: 20.63312333928601,
        award_ident: ""
    },
    {
        name: "Nora Aktora",
        address: "Rynek 8, 35-064 Rzeszów",
        lat: 50.03704336623942,
        lng: 22.0048253405763,
        award_ident: ""
    },
    {
        name: "Pewex",
        address: "Rynek 18, 35-064 Rzeszów",
        lat: 50.03779823978587,
        lng: 22.005373575319705,
        award_ident: ""
    },
    {
        name: "Pijalnia Rzeszowska",
        address: "Grunwaldzka 1, 35-068 Rzeszów",
        lat: 50.03791476469403,
        lng: 22.002589655999532,
        award_ident: ""
    },
    {
        name: "Pijalnia Wódki i Piwa",
        address: "Świętego Leonarda 23, 25-001 Kielce",
        lat: 50.871070048748415,
        lng: 20.63170398349892,
        award_ident: ""
    },
    {
        name: "PIJALNIA WÓDKI I PIWA",
        address: "Adama Mickiewicza 4, 35-064 Rzeszów",
        lat: 50.03745578870177,
        lng: 22.006358107976563,
        award_ident: ""
    },
    {
        name: "Plejada",
        address: "Rynek 16, 25-001 Kielce",
        lat: 50.87132309914044,
        lng: 20.63128687885789,
        award_ident: ""
    },
    {
        name: "PUB SODA",
        address: "Rynek 15, 35-330 Rzeszów",
        lat: 50.037492879406955,
        lng: 22.005827051520658,
        award_ident: ""
    },
    {
        name: "ShotBar ShotGun",
        address: "Rynek 8, 25-303 Kielce",
        lat: 50.871561936099766,
        lng: 20.632468453670455,
        award_ident: ""
    },
    {
        name: "Tapas",
        address: "Wesoła 10, 25-305 Kielce",
        lat: 50.871528893558036,
        lng: 20.63395578489867,
        award_ident: ""
    },
    {
        name: "ALCHEMIA",
        address: "Estery 5, 31-056 Kraków",
        lat: 50.05220341955651,
        lng: 19.945008040776457,
        award_ident: ""
    },
    {
        name: "BANIALUKA Plac Szczepański",
        address: "Plac Szczepański 6, 33-332 Kraków",
        lat: 50.064195701705614,
        lng: 19.936057156325766,
        award_ident: ""
    },
    {
        name: "BANIALUKA ul. Floriańska",
        address: "Floriańska 3, 31-019 Kraków",
        lat: 50.06229629087802,
        lng: 19.93992466249493,
        award_ident: ""
    },
    {
        name: "BANIALUKA ul. Szewska",
        address: "Szewska 13, 31-009 Kraków",
        lat: 50.06282843918415,
        lng: 19.93490164160394,
        award_ident: ""
    },
    {
        name: "BEER HOUSE AND PUB& RESTAURANT",
        address: "Floriańska 45, 31-019 Kraków",
        lat: 50.0639981008845,
        lng: 19.940893367841067,
        award_ident: ""
    },
    {
        name: "DYM Cafe Bar",
        address: "Świętego Tomasza 13, 31-014 Kraków",
        lat: 50.06303457823673,
        lng: 19.938834422608814,
        award_ident: ""
    },
    {
        name: "FORTY KLEPARZ",
        address: "Kamienna 2/4, 30-001 Kraków",
        lat: 50.07443721351588,
        lng: 19.938003391473295,
        award_ident: ""
    },
    {
        name: "GORALSKI BROWAR",
        address: "Krupówki 40, Zakopane",
        lat: 49.292802724418856,
        lng: 19.956134684032428,
        award_ident: ""
    },
    {
        name: "Gorzko Gorzko",
        address: "Plac Szczepański 7, 31-011 Kraków",
        lat: 50.064122086775456,
        lng: 19.936158006777738,
        award_ident: ""
    },
    {
        name: "HEVRE",
        address: "Beera Meiselsa 18, 31-058 Kraków",
        lat: 50.05121663174046,
        lng: 19.94343129084892,
        award_ident: ""
    },
    {
        name: "KLUB POD JASZCZURAMI",
        address: "Rynek Główny 8, 31-042 Kraków",
        lat: 50.06099169471707,
        lng: 19.938183093726643,
        award_ident: ""
    },
    {
        name: "LE SCANDALE",
        address: "Mariusza Zaruskiego 5, Zakopane",
        lat: 49.294509114373106,
        lng: 19.950030968683414,
        award_ident: ""
    },
    {
        name: "Le Scandale Garden",
        address: "Plac Nowy 9, 31-045 Kraków",
        lat: 50.0519244727853,
        lng: 19.944512912373224,
        award_ident: ""
    },
    {
        name: "LES COULEURS CAFE",
        address: "Estery 10, 31-056 Kraków",
        lat: 50.051969071499244,
        lng: 19.945357956244415,
        award_ident: ""
    },
    {
        name: "Międzymiastowa Podgórze",
        address: "Nadwiślańska 5/L1, 30-527 Kraków",
        lat: 50.046415694589136,
        lng: 19.94980152754409,
        award_ident: ""
    },
    {
        name: "MIĘDZYMIASTOWA RYNEK",
        address: "Rynek Główny 34, 31-010 Kraków",
        lat: 50.062491428797216,
        lng: 19.93633739192252,
        award_ident: ""
    },
    {
        name: "NA KOŃCU ŚWIATA",
        address: "Witkiewicza 10, Zakopane",
        lat: 49.29192821728346,
        lng: 19.962239611012663,
        award_ident: ""
    },
    {
        name: "PAUZA",
        address: "Stolarska 5, 31-043 Kraków",
        lat: 50.06066130654121,
        lng: 19.93890331063945,
        award_ident: ""
    },
    {
        name: "PIJALNIA WÓDKI I PIWA Floriańska",
        address: "Floriańska 34, 31-019 Kraków",
        lat: 50.064266952158974,
        lng: 19.940701248539902,
        award_ident: ""
    },
    {
        name: "PIJALNIA WÓDKI I PIWA Kazimierz",
        address: "Plac Nowy 7, 33-332 Kraków",
        lat: 50.051735050701716,
        lng: 19.944372574935688,
        award_ident: ""
    },
    {
        name: "PIJALNIA WÓDKI I PIWA Szewska",
        address: "Szewska 25, 31-009 Kraków",
        lat: 50.06286894210593,
        lng: 19.93405407050402,
        award_ident: ""
    },
    {
        name: "RESTAURACJA GUBAŁÓWKA",
        address: "Droga Stanisława Zubka 8, Zakopane",
        lat: 49.30687817123789,
        lng: 19.93353085249115,
        award_ident: ""
    },
    {
        name: "Scandale Royal",
        address: "Plac Szczepański 2, 31-011 Kraków",
        lat: 50.06334280106626,
        lng: 19.93499572651828,
        award_ident: ""
    },
    {
        name: "STARA ZAJEZDNIA",
        address: "Świętego Wawrzyńca 12, 31-060 Kraków",
        lat: 50.05024642891311,
        lng: 19.94720182887311,
        award_ident: ""
    },
    {
        name: "Tajemniczy Ogród",
        address: "Plac Nowy 9, 33-332 Kraków",
        lat: 50.05203682418253,
        lng: 19.944697604882364,
        award_ident: ""
    },
    {
        name: "TEATRALNA",
        address: "Plac Świętego Ducha 1, 31-021 Kraków",
        lat: 50.06410588153159,
        lng: 19.942625677332863,
        award_ident: ""
    },
    {
        name: "8 STOLIKÓW",
        address: "Rynek 12, Bielsko Biała",
        lat: 49.821644481133596,
        lng: 19.042587455152294,
        award_ident: ""
    },
    {
        name: "Chilli Street Food Bar",
        address: "Rynek 26, Bielsko Biała",
        lat: 49.82099473162568,
        lng: 19.043010268817657,
        award_ident: ""
    },
    {
        name: "CRAFTBAR",
        address: "Rynek 10, Bielsko Biała",
        lat: 49.82152056458556,
        lng: 19.042353055323776,
        award_ident: ""
    },
    {
        name: "Szpilka & Dog's Bollocks",
        address: "Rynek 29, Bielsko Biała",
        lat: 49.82100179767756,
        lng: 19.042844468817634,
        award_ident: ""
    },
    {
        name: "Museum Music Republic",
        address: "Sobieskiego 2, Bytom",
        lat: 50.34924174090311,
        lng: 18.925693811168227,
        award_ident: ""
    },
    {
        name: "HARD ROCK",
        address: "Jagielońska 4, Chorzów",
        lat: 50.29769765099052,
        lng: 18.952165097672175,
        award_ident: ""
    },
    {
        name: "ZIELONA DOLINKA",
        address: "Różana 3, Chorzów",
        lat: 50.281713476658744,
        lng: 18.98026570890958,
        award_ident: ""
    },
    {
        name: "Bristol",
        address: "Mikołaja Kopernika 16, Częstochowa",
        lat: 50.809039031559514,
        lng: 19.115385268857544,
        award_ident: ""
    },
    {
        name: "La Playa",
        address: "Kordeckiego 99, Częstochowa",
        lat: 50.81045907095266,
        lng: 19.072054468857615,
        award_ident: ""
    },
    {
        name: "MINISTERSTWO Śledzia i Wódki",
        address: "Śląska 7, Częstochowa",
        lat: 50.81057065956245,
        lng: 19.114002452232743,
        award_ident: ""
    },
    {
        name: "WARKA",
        address: "Mikołaja Kopernika 16, Częstochowa",
        lat: 50.80901747232692,
        lng: 19.11538526885759,
        award_ident: ""
    },
    {
        name: "MINISTERSTWO Śledzia i Wódki Raciborska 5/II",
        address: "Raciborska 2, Gliwice",
        lat: 50.294447514633674,
        lng: 18.664512838397442,
        award_ident: ""
    },
    {
        name: "POWSTAŃCZA RYNEK",
        address: "Rynek 24-26, GLIWICE",
        lat: 50.29377733739568,
        lng: 18.666021097672054,
        award_ident: ""
    },
    {
        name: "AIOLI",
        address: "Rynek 5, Katowice",
        lat: 50.260128576254196,
        lng: 19.0216735414516,
        award_ident: ""
    },
    {
        name: "AMBASADA",
        address: "Staromiejska 14, Katowice",
        lat: 50.25852094537945,
        lng: 19.024691155341337,
        award_ident: ""
    },
    {
        name: "AMBASADA ŚLEDZIA",
        address: "Mariacka 25, Katowice",
        lat: 50.25723317081956,
        lng: 19.027781210444758,
        award_ident: ""
    },
    {
        name: "AMNEZJA",
        address: "Mariacka 16, Katowice",
        lat: 50.2575659424085,
        lng: 19.02682565802818,
        award_ident: ""
    },
    {
        name: "Bar A Boo",
        address: "Mariacka 37, KATOWICE",
        lat: 50.25704945677293,
        lng: 19.02926173344466,
        award_ident: ""
    },
    {
        name: "BIAŁA MAŁPA",
        address: "ul. 3 maja 38, Katowice",
        lat: 50.25959608840689,
        lng: 19.016393406205463,
        award_ident: ""
    },
    {
        name: "CITY PUB",
        address: "ul. 3 maja 23, KATOWICE",
        lat: 50.26029419823448,
        lng: 19.016774520659762,
        award_ident: ""
    },
    {
        name: "CLUB CAPITOL",
        address: "Mariacka 20, Katowice",
        lat: 50.25729740440058,
        lng: 19.02642736172582,
        award_ident: ""
    },
    {
        name: "DOLCE VITA",
        address: "Mariacka 20, Katowice",
        lat: 50.25726807050414,
        lng: 19.02647346269617,
        award_ident: ""
    },
    {
        name: "DRZWI ZWANE KONIEM",
        address: "Warszawska 37, Katowice",
        lat: 50.25790252795733,
        lng: 19.029739017137633,
        award_ident: ""
    },
    {
        name: "EMCEK Meet & Eat",
        address: "plac Sławika i Antala 1, Katowice",
        lat: 50.265434143927465,
        lng: 19.026573726506264,
        award_ident: ""
    },
    {
        name: "GALIMATJAS",
        address: "Mariacka 9, Katowice",
        lat: 50.257357248854476,
        lng: 19.02581286649055,
        award_ident: ""
    },
    {
        name: "GREENPOINT PUB",
        address: "Mariacka 4, Katowice",
        lat: 50.257780169302684,
        lng: 19.025154526505982,
        award_ident: ""
    },
    {
        name: "KLUBOWA",
        address: "Mariacka 7, Katowice",
        lat: 50.257429509836925,
        lng: 19.02559754976425,
        award_ident: ""
    },
    {
        name: "Kominiarz Pub",
        address: "Wojewodzka 24, Katowice",
        lat: 50.2563854858274,
        lng: 19.02417645806289,
        award_ident: ""
    },
    {
        name: "LORNETA&MEDUZA",
        address: "Mariacka 5, Katowice",
        lat: 50.25738765381732,
        lng: 19.025283905984203,
        award_ident: ""
    },
    {
        name: "MINISTERSTWO Śledzia i Wódki",
        address: "Mariacka 4, Katowice",
        lat: 50.25765746445177,
        lng: 19.02512627882848,
        award_ident: ""
    },
    {
        name: "PIJALNIA WÓDKI I PIWA",
        address: "Mariacka 8, Katowice",
        lat: 50.2578067140095,
        lng: 19.025763969077694,
        award_ident: ""
    },
    {
        name: "PIWIARNIA MARIACKA",
        address: "Mariacka 6, Katowice",
        lat: 50.25766327671462,
        lng: 19.025486944294858,
        award_ident: ""
    },
    {
        name: "POMARAŃCZA",
        address: "Mickiewicza 4, Katowice",
        lat: 50.259882054974504,
        lng: 19.021024563469975,
        award_ident: ""
    },
    {
        name: "PUNKT WIDZENIA",
        address: "Mariacka 16, Katowice",
        lat: 50.2575085603404,
        lng: 19.026904274312805,
        award_ident: ""
    },
    {
        name: "Rajzefiber",
        address: "ul. 3 maja 23, KATOWICE",
        lat: 50.26003777735824,
        lng: 19.016653923710763,
        award_ident: ""
    },
    {
        name: "SĄSIEDZTWO",
        address: "Mariacka 10, Katowice",
        lat: 50.25768964231813,
        lng: 19.02591737927075,
        award_ident: ""
    },
    {
        name: "Sixa Club",
        address: "3 Maja 23/3, KATOWICE",
        lat: 50.26010762424743,
        lng: 19.016582871293355,
        award_ident: ""
    },
    {
        name: "Spiż Mini Browar i Restauracja",
        address: "Opolska 22, Katowice",
        lat: 50.26229947690429,
        lng: 19.011340326506172,
        award_ident: ""
    },
    {
        name: "Celtic Pub",
        address: "Rynek 8, Rybnik",
        lat: 50.095617853698855,
        lng: 18.542489140226717,
        award_ident: ""
    },
    {
        name: "NOC",
        address: "Kościelna 6, Rybnik",
        lat: 50.09683038595972,
        lng: 18.541887380712375,
        award_ident: ""
    },
    {
        name: "Inferno Pub",
        address: "Targowa 9, Sosnowiec",
        lat: 50.27738555888014,
        lng: 19.129298502135956,
        award_ident: ""
    },
    {
        name: "KSIĘGARNIA",
        address: "Małachowskiego 22, Sosnowiec",
        lat: 50.27696273727234,
        lng: 19.131576666644996,
        award_ident: ""
    },
    {
        name: "MINISTERSTWO Śledzia i Wódki",
        address: "Małachowskiego 32, Sosnowiec",
        lat: 50.27630572612689,
        lng: 19.132134699974078,
        award_ident: ""
    },
    {
        name: "PIJALNIA WÓDKI I PIWA",
        address: "Warszawska 3, Sosnowiec",
        lat: 50.27786626940376,
        lng: 19.12715480396446,
        award_ident: ""
    },
    {
        name: "PIWIARNIA WARSZAWSKA",
        address: "Warszawska 21, Sosnowiec",
        lat: 50.27599544306174,
        lng: 19.12645175940064,
        award_ident: ""
    },
    {
        name: "Kosmata",
        address: "Piastowska 13, Tarnowskie Góry",
        lat: 50.44339247563037,
        lng: 18.85880611914837,
        award_ident: ""
    },
    {
        name: "MINISTERSTWO Śledzia i Wódki",
        address: "Dolna 1, Tarnowskie Góry",
        lat: 50.444220251711606,
        lng: 18.853504094559355,
        award_ident: ""
    },
    {
        name: "AWANGARDA",
        address: "Dmowskiego 4, TYCHY",
        lat: 50.1126967400271,
        lng: 18.985667789919148,
        award_ident: ""
    },
    {
        name: "MINISTERSTWO Śledzia i Wódki",
        address: "Jana Pawła II 10, Tychy",
        lat: 50.11175130711881,
        lng: 18.986412925209944,
        award_ident: ""
    },
    {
        name: "PIJALNIA WÓDKI I PIWA",
        address: "Jana Pawła II 10, Tychy",
        lat: 50.112393822369874,
        lng: 18.986349240227423,
        award_ident: ""
    },
    {
        name: "PROMENADA",
        address: "Parkowa 17, Tychy",
        lat: 50.087422701347776,
        lng: 18.992833969062968,
        award_ident: ""
    },
    {
        name: "PRZEJDŹMY NA TY",
        address: "Jana Pawła II 10, Tychy",
        lat: 50.1119316342602,
        lng: 18.986109928584106,
        award_ident: ""
    },
    {
        name: "WIELOKRAN",
        address: "plac Baczyńskiego 2, Tychy",
        lat: 50.12676104832854,
        lng: 18.98197445371822,
        award_ident: ""
    },
    {
        name: "Niebostan",
        address: "Piotrkowska 17 ,90-406 Łódź",
        lat: 51.77482577827947,
        lng: 19.454627873015326,
        award_ident: ""
    },
    {
        name: "Kij",
        address: "Stefana Jaracza 45, 90-249 Łódź",
        lat: 51.77319485769127,
        lng: 19.46703799618496,
        award_ident: ""
    },
    {
        name: "Ministerstwo Śledzia i Wódki",
        address: "Piotrkowska 84, 90-102 Łódź",
        lat: 51.7670130571106,
        lng: 19.457174768989148,
        award_ident: ""
    },
    {
        name: "Ministerstwo Śledzia i Wódki",
        address: "Piotrkowska 111, 90-425 Łódź",
        lat: 51.76417204198774,
        lng: 19.457207917610177,
        award_ident: ""
    },
    {
        name: "Pijalnia Wódki i Piwa",
        address: "Piotrkowska 92, 90-001 Łódź",
        lat: 51.76649869242979,
        lng: 19.457371870840333,
        award_ident: ""
    },
    {
        name: "Klubopiwiarnia",
        address: "Prezydenta Gabriela Narutowicza 7, 90-001 Łódź",
        lat: 51.77030794457658,
        lng: 19.458585368989326,
        award_ident: ""
    },
    {
        name: "Drukarnia",
        address: "Piotrkowska 138/140, 90-062 Łódź",
        lat: 51.76129672341739,
        lng: 19.460333370181925,
        award_ident: ""
    },
    {
        name: "Willa",
        address: "aleje Politechniki 117, 90-050 Łódź",
        lat: 51.75492806697651,
        lng: 19.43932152666051,
        award_ident: ""
    },
    {
        name: "Na Językach",
        address: "Stanisława Moniuszki 1, 90-102 Łódź",
        lat: 51.768110906750024,
        lng: 19.457289413168727,
        award_ident: ""
    },
    {
        name: "Bajka Disco Club",
        address: "Piotrkowska 90, 90-103 Łódź",
        lat: 51.76679513235831,
        lng: 19.458467642004493,
        award_ident: ""
    },
    {
        name: "Piwoteka",
        address: "Gen. Romualda Traugutta 4, 90-107 Łódź",
        lat: 51.76911820289787,
        lng: 19.458098553645694,
        award_ident: ""
    },
    {
        name: "Chmielowa Dolina",
        address: "Piotrkowska 123, 90-430 Łódź",
        lat: 51.762981865277325,
        lng: 19.457538226660805,
        award_ident: ""
    },
    {
        name: "Pewex",
        address: "Prezydenta Gabriela Narutowicza 28, 90-135 Łódź",
        lat: 51.77136576007785,
        lng: 19.461287511317618,
        award_ident: ""
    },
    {
        name: "Fabryka Krawatów Bistro",
        address: "Piotrkowska 90, 90-103 Łódź",
        lat: 51.76660577408019,
        lng: 19.45732049782507,
        award_ident: ""
    },
    {
        name: "Biblioteka",
        address: "Andrzeja Struga 7, 90-420 Łódź",
        lat: 51.765592856828974,
        lng: 19.45492462666092,
        award_ident: ""
    },
    {
        name: "Agrafka",
        address: "Piotrkowska 90, 90-432 Łódź",
        lat: 51.76677991183988,
        lng: 19.457804940153224,
        award_ident: ""
    }
]