export const errorDefinitions = [
  {
    ident: "error_redeem_cooldown",
    title:"Nie tak szybko. Ta nagroda została niedawno odebrana",
    desc:"Nie możesz jeszcze odebrać kolejnej nagrody tego typu. Będzie gotowa do ponownego odbioru już wkrótce.",
    button:"Zamknij",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_unauthorized",
    title: "Wylogowano lub sesja wygasła.",
    desc: "Przejdź do strony logowania.",
    button: "Zaloguj się ponownie",
    returnUrl: process.env.REACT_APP_BRANDED_URL+"/strefa-punktow/skaner-zone?logout=true",
    target: "_self",
    logout: true,
    disableClose: true,
    updateUserData: false
  },
  {
    ident: "error_account_temporary_blocked",
    title: "Twoje konto zostało tymczasowo zablokowane.",
    desc: "<p>Wykryliśmy nietypową aktywność na Twoim koncie. Aktualnie weryfikujemy tę sytuację i wkrótce skontaktujemy się z Tobą.</p><p>Jeśli potrzebujesz pomocy, napisz na adres: <a href=\"mailto:kontakt@alterzone.pl\">kontakt@alterzone.pl</a><br/>Przepraszamy za niedogodności.</p>",
    button: "Zamknij",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_m2_already_registered",
    title: "Konto o podanym adresie już istnieje.",
    desc: "Spróbuj się zalogować. Jeśli posiadasz konto w sklepie internetowym glo™, zaloguj się danymi tego konta i w kolejnym kroku wyraź zgodę na przetwarzanie danych.",
    button: "Zamknij",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_m2_wrong_credentials_or_disabled",
    title: "Nieprawidłowe hasło, lub konto nie istnieje.",
    desc: "Spróbuj jeszcze raz, albo kliknij &quot;nie pamiętam hasła&quot;, by je zresetować.",
    button: "Zaloguj się ponownie",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_m2_empty_field",
    title: "Wystąpił błąd.",
    desc: " Spróbuj jeszcze raz.",
    button: "Zamknij",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_token_has_been_already_used",
    title: "Wystąpił błąd.",
    desc: "Przejdź do resetu hasła.",
    button: "Zaloguj się ponownie",
    returnUrl: null,
    disableClose: true,
    updateUserData: false
  },
  {
    ident: "error_customer_not_found_by_email",
    title: "Wystąpił błąd.",
    desc: "Zacznij od początku.",
    button: "Zamknij",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_customer_not_found_by_token",
    title: "Wystąpił błąd.",
    desc: "Zacznij od początku.",
    button: "Zaloguj się ponownie",
    returnUrl: null,
    disableClose: true,
    updateUserData: false
  },
  {
    ident: "error_already_answered",
    title: "Wystąpił błąd.",
    desc: "Odpowiedź na pytanie konkursowe została już przesłana.",
    button: "Zamknij",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_token_expired",
    title: "Wystąpił błąd.",
    desc: 'Twój link aktywacyjny już stracił ważność. Aby dokończyć rejestrację, skorzystaj z opcji "nie pamiętam hasła" podając swój adres e-mail.',
    button: "Zaloguj się ponownie",
    returnUrl: null,
    disableClose: true,
    updateUserData: false
  },
  {
    ident: "error_voucher_already_assigned",
    title: "Wystąpił błąd.",
    desc: "Podany numer telefonu istnieje już w bazie, spróbuj ponownie podając nowy numer telefonu",
    button: "Zamknij",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_verification_required",
    title: "Ograniczyliśmy dostęp do Twojego konta.",
    desc: '<p>Wykryliśmy nietypową aktywność na koncie, do którego próbujesz się zalogować.* Ze względów bezpieczeństwa i w celu zweryfikowania informacji tymczasowo zablokowaliśmy do niego dostęp.</p><p>Aby odblokować konto, postępuj zgodnie z instrukcją, którą wysłaliśmy na Twój adres e-mail.</p><p class="disclaimer">*Blokada konta pojawia się, jeśli wykryjemy naruszenie zasad korzystania z platformy Masz Wybór. <a href="/assets/regulamin_serwisu_maszwybor.pdf" target="_blank">Dowiedz się więcej</a></p>',
    button: "Zamknij",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_m2_token_error",
    title: "Ups, coś poszło nie tak.",
    desc: '<p>Skorzystaj z opcji "nie pamiętam hasła", by ustawić nowe hasło.</p>',
    button: "Zamknij",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "first_voucher",
    title: "Cześć!",
    desc: "<p><strong>Przy wymianie ponad 200 punktów w danym miesiącu na nagrody, potrzebne będzie uzupełnienie dodatkowych danych koniecznych do wystawienia Ci PIT-11.</strong></p><p>Przy próbie pobrania nagrody skontaktujemy się z Tobą mailowo w celu zebrania niezbędnych danych, a następnie przekażemy Ci Twoją nagrodę.</p>",
    button: "Zamknij",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_monthly_limit_exceeded", // Po kliknięciu "Dalej" docelowo pokazujemy tax form, przydałby sie projekt. Do uzupełnienia opis poniżej.
    title: "Wystąpił błąd.",
    desc: "<p><strong>Aktualnie nie można odebrać nagrody, przepraszamy za problemy techniczne.</strong></p>",
    button: "Dalej",
    returnUrl: null,
    disableClose: false,
    updateUserData: false,
    customAction: "showTaxForm" // Ident of custom action.
  },
  {
    ident: "error_prize_exception",
    title: "Wystąpił błąd.",
    desc: "<p><strong>Aktualnie nie można odebrać nagrody, przepraszamy za problemy techniczne.</strong></p>",
    button: "Zamknij",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "generic",
    title: "Ups, coś poszło nie tak.",
    desc: "Wystąpił błąd. Spróbuj jeszcze raz.",
    button: "Zamknij",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_contest_closed",
    title: "Wystąpił błąd.",
    desc: 'Konkurs stracił ważność.',
    button: "Zamknij",
    returnUrl: null,
    disableClose: true,
    updateUserData: false
  },
  {
    ident: "error_slot_chosen",
    title: "Wystąpił błąd.",
    desc: 'Miejsce na lot zostało już zarezerwowane.',
    button: "Zamknij",
    returnUrl: "null",
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_slot_taken",
    title: "Wystąpił błąd.",
    desc: 'Wybrana data i godina jest już niedostępna',
    button: "Zamknij",
    returnUrl: "null",
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_daily_limit_exceeded",
    type: "small_popup_random",
    title: "",
    desc: "",
    randomCopy: [
      {
        title: "To Twoja ulubiona nagroda?",
        desc: "Widzimy, że trafiła ona już dziś do Ciebie. Wróć jutro, aby odebrać ją ponownie lub przejrzyj katalog i&nbsp;wybierz coś równie interesującego!"
      },
      {
        title: "Co jest grane?",
        desc: "Ahh… kolejna taka sama nagroda. Jeżeli tak bardzo Ci na niej zależy, to wróć po nią jutro. Chociaż może zerkniesz na katalog? Są tam równie interesujące nagrody. "
      },
      {
        title: "Uwielbiasz tę nagrodę?",
        desc: "Widzimy, że trafiła ona już dziś do Ciebie. Wróć jutro, aby odebrać ją ponownie lub przejrzyj katalog i&nbsp;dokonaj kolejnego wyboru! "
      },
      {
        title: "Dajesz czadu!",
        desc: "Pozwól sobie na chwilę radości z poprzednią nagrodą. Jutro odbierzesz ją ponownie. A&nbsp;jeśli nie chcesz czekać z&nbsp;założonymi rękami – zajrzyj do katalogu po inne fajne kupony."
      },
      {
        title: "Wyszło na jaw, że pociąga Cię ta nagroda.",
        desc: "Widzimy, że trafiła ona już dziś do Ciebie. Poczekaj do jutra, aby ponownie się z nią spotkać. Chociaż po co czekać, jeśli można wydać punkty na inną nagrodę."
      }
    ],
    button: "Katalog nagród",
    returnUrl: "/nagrody/katalog",
    disableClose: false,
    updateUserData: false
  }
]

export const customActions = {
  showTaxForm: () => { console.log("showTaxForm") }
}