import React from "react"
import Logo from "../../../img/alterzone-logo-white.svg"
import FB from "./img/fb.svg"
import Insta from "./img/insta.svg"
import Service from "./img/service.svg"
import "./style.scss"

export default function Maintenance () {
  return (
    <div className="maintenance">
      <div className="maintenance-inner">
        <img className="logo" src={ Logo } alt="glo logo" />
        <img src={Service} alt="" className="service-img"/>
        <h2>
          Trwają prace modernizacyjne.
        </h2>
        <p>
          Przepraszamy za&nbsp;niedogodności. Zapraszamy ponownie wkrótce.
        </p>
        <p><strong>Pozostań z nami w kontakcie</strong></p>
        <ul className="social">
          <li>
            <a href="https://www.facebook.com/Glo-Polska-109341744534828/" target="_blank" rel="noopener noreferrer"><img src={FB} alt="FB"/></a>
          </li>
          <li>
            <a href="https://www.instagram.com/glo_polska/" target="_blank" rel="noopener noreferrer"><img src={Insta} alt="Insta"/></a>
          </li>
        </ul>
      </div>
    </div>
  )
}
