import React from 'react';
import ReactDOM from 'react-dom/client';
import "normalize.css";
import "./styles/index.scss";
import MainView from './app/MainView';
import { UserProvider } from './app/UserContext';
import { initUrlParams } from './app/UrlParamsHelper';

const clearStorage = () => {
  if(document.location.search.includes("clear")) {
    localStorage.removeItem("firstRun")
    localStorage.removeItem("awardCollected")
  }
}

clearStorage()
initUrlParams()

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <UserProvider>
    <MainView />
  </UserProvider>
);
